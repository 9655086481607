import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { HomeComponent } from '../home/home.component';
import { User } from '../datamodal/index';
import { AuthenticationService, SidebarLocatorService } from '../service/index';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectedPayPeriodModel} from '../datamodal/payperiodmodal';
import { PayrollStatus, UserPriviledge } from '../enum/enum';

@Component({
  selector: 'app-payroll-shell',
  templateUrl: './payroll-shell.component.html',
  styleUrls: ['./payroll-shell.component.scss']
})
export class PayRollShellComponent implements OnInit {
  @ViewChild('sidebar') sidebar: SidebarComponent;
  @ViewChild(HomeComponent)homeComponent:HomeComponent;
  @ViewChild("childComponent")childComponent:HomeComponent;

  public currentUser: User;
  public logoData = '';
  public titleData = "Time Entry Approval";
  public showMenuBar:boolean=false;
  public MenuClass:string='';
  
  constructor(private router: Router, private authenicationServie: AuthenticationService,
    private sidebarService: SidebarLocatorService, private spinner: NgxSpinnerService,
    private selectedPayPeriodModel:SelectedPayPeriodModel) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // this.authenicationServie.getCurrentCompanyLogo().subscribe(response => {
    //   this.logoData = 'data:image/jpg;base64,' + response.json();
    // });

  }

  onLoadLoader(componentRef) {
    componentRef.works();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.currentUser[0].roleId === UserPriviledge.Administrator) {
        this.showMenuBar=true;
        this.MenuClass="marginLeft";
      }else{
        this.showMenuBar=false;
        this.MenuClass="m-l-30";
      }
   
  }


  onLoader(event: any) {
    if (event === "true") {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  SwitchSubpage(path: string) {
    this.router.navigate([path]);
  }
  

}