import {CdkTableModule} from '@angular/cdk/table';
import {HttpClientModule} from '@angular/common/http';
import { HttpModule } from '@angular/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AlertService, AuthenticationService , UserService } from './service/index';
import { NgxSpinnerModule } from 'ngx-spinner';


import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatFormFieldModule 
   
} from '@angular/material';
import {BrowserModule} from '@angular/platform-browser';
import { StringFilter } from './pipes/stringFilter.pipe';


import { DateFilter } from './pipes/dateFilter.pipe';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {TableBasicExample} from './app/table-basic-example';
import { AppBootstrapModule } from './app.bootstrapModule';


//Services
import { HttpService } from './service/http.service';
import{PollfileService} from './service/pollfile.service'
import { SidebarLocatorService } from './service/sidebar-locator.service';

import { AppRoutingModule } from './app-routing.module';
import 'hammerjs';
import { AlertComponent } from './directive/index';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/index';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { RegisterComponent } from './register/index';

import { RequestAccessSiteDialog } from './dialogs/requestaccesssite/requestaccesssite.dialog';
import { EmployeeDetailDialog } from './dialogs/employeedetail/employeedetail.dialog';
import { ExpandDetailsPopup } from './dialogs/expanddetailspopup/expanddetailspopup.component';
import { PayrollRejectionDialog } from './dialogs/payrollrejection/payrollrejection.dialog';


import { ConfirmDialog } from './dialogs/confirm.dialog';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavComponent } from './nav/nav.component';
import { UserMaintenanceComponent } from './usermaintenance/index';
import { MaintenanceShellComponent } from './maintenance-shell/maintenance-shell.component';
import { PayRollShellComponent } from './payroll-shell/payroll-shell.component';
import { PayrollreportComponent } from './report/payroll/payrollreport.component';
import { ReportShellComponent } from './report-shell/report-shell.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import {SelectedPayPeriodModel} from './datamodal/payperiodmodal'
import { AddUserPopup } from './dialogs/adduserpopup/adduserpopup.component';
import {UploadOpenSubmitPopup } from './dialogs/uploadopensubmitpopup/uploadopensubmitpopup.component';
import { StoreMaintenanceComponent } from './storemaintenance/index';

@NgModule({
  exports: [
    CdkTableModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule ,
  ]
})
export class DemoMaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    RequestAccessSiteDialog ,
    EmployeeDetailDialog ,
    PayrollRejectionDialog ,
    ConfirmDialog ,
    AddUserPopup,
    DateFilter,
    StringFilter,
    AlertComponent,
    LoginComponent,
    ForgetpasswordComponent,
    RegisterComponent ,
    SidebarComponent ,
    NavComponent,
    MaintenanceShellComponent ,
    PayRollShellComponent ,
    ReportShellComponent ,
    UserMaintenanceComponent ,
    PayrollreportComponent,
    ResetpasswordComponent,
    ExpandDetailsPopup,
    UploadOpenSubmitPopup,
    StoreMaintenanceComponent 
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppBootstrapModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    DemoMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule
  ],
  providers: [
    HttpService,
    PollfileService ,
    AlertService,
    AuthenticationService,
    UserService ,
    SidebarLocatorService,
    SelectedPayPeriodModel
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: '6LeK-HIUAAAAAL7Dqc_vQ9SRRMFxjKOQjWqJQuGC',
    //   } as RecaptchaSettings,
    // }
  ],
  entryComponents: [
    RequestAccessSiteDialog,
    EmployeeDetailDialog ,
    PayrollRejectionDialog ,
    ConfirmDialog,
    AddUserPopup,
    UploadOpenSubmitPopup
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
