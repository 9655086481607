import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject'

import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Headers, RequestOptions } from '@angular/http';
import { User } from '../datamodal/index';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class PollfileService {

  private goals = new BehaviorSubject<any>(['test', 'my second gol']);
  goal = this.goals.asObservable();
  constructor(private httpService: HttpService) { }

  changeGoal(goal) {
    this.goals.next;
  }

  getPollFileLookups() {
    return this.httpService.postRequest('api/Poll/GetPollFileLookup', {
     });
  }


  getGeneralPollFileList(storeId: string, periodId: number , payRoleStatusID: number) {
    return this.httpService.postRequest('api/Poll/GetGeneralPollFileReview', {
      storeId: storeId,
      periodId: periodId,
      payRoleStatusID: payRoleStatusID

    });
  }
  getPollFileList(storeId: string, periodId: number ) {
    return this.httpService.postRequest('api/Poll/GetPollFileReview', {
      storeId: storeId,
      periodId: periodId

    });
  }

  RenderADPFile(storeId: string, periodId: number ) {
    return this.httpService.postRequest('api/Poll/GetADPFile', {
      storeId: storeId,
      periodId: periodId

    });
  }


  getDistinctPollFileList(storeId: string, periodId: number , payRoleStatusID: number) {
    return this.httpService.postRequest('api/Poll/GetDistinctPollFileReview', {
      storeId: storeId,
      periodId: periodId,
      payRoleStatusID: payRoleStatusID

    });
  }


  getPayrollPollFileList(storeId: string, periodId: number , payRoleStatusID: number) {
    return this.httpService.postRequest('api/Poll/GetPayrollPollFileReview', {
      storeId: storeId,
      periodId: periodId,
      payRoleStatusID: payRoleStatusID

    });
  }

  getPollFileSummaryList(storeId: string, periodId: number ) {
    return this.httpService.postRequest('api/Poll/getPollFileSummaryReview', {
      storeId: storeId,
      periodId: periodId
    });
  }

  getPayrollNewPollFileList(storeId: string, periodId: number ) {
    return this.httpService.postRequest('api/Poll/GetNewPayrollPollFileReview', {
      storeId: storeId,
      periodId: periodId
    });
  }

  getPollFileFromBachNo(bachNo: number, storeId:number ,  periodId: number  , user: User) {
    return this.httpService.postRequest('api/Poll/GetPollFileFromBachNo', {
      bachNo: bachNo,
      storeId: storeId,
      periodId: periodId,
      CurrentUser: { UserId: user.id, UserName: user.fullName, Password: user.password }

    });
  }

//   getStore() {
//     let model ={};
//     return this.httpService.postRequest('API/Account/GetStore', model).map((response: Response) => response.json());
// }

  getStoreIdFromBachNo(bachNo: number,  user: User) {
    return this.httpService.postRequest('api/Poll/GetStoreIdFromBachNo', {
      bachNo: bachNo,
      CurrentUser: { UserId: user.id, UserName: user.fullName, Password: user.password }

    }).map((response: Response) => response.json());
  }

  getBatchNumber(storeId:number ,  periodId: number) {
    let model ={
      storeId:storeId ,
      periodId:periodId
    };
    return this.httpService.postRequest('api/Poll/GetBatchNumberFromPayrollEntry', model).map((response: Response) => response.json());
}


  saveworkGPBatchHeader(workPayroll: any , user: User,StoreId:string,PeriodId:number){
    const body = {
        CurrentUser: { UserId: user.id, UserName: user.fullName, Password: user.password },
        WorkPayroll: workPayroll,
        StoreId:StoreId,
        PeriodId:PeriodId
    };
    return this.httpService.postRequest('API/Accounting/PostWorkGPBatchAsync', body).map((response: Response) => response.json());
}

updateGeneralAndDistrinctEntry( payroll: any,StoreId:string, PeriodId:number) {
  return this.httpService.postRequest('API/Poll/UpdateGeneralAndDistrinctEntry', {payrollData:payroll,storeId:StoreId,periodId:PeriodId}).map((response: Response) => response.json());
}

savePayrollSummaryDetails( payroll: any,StoreId:string, PeriodId:number) {
  return this.httpService.postRequest('API/Poll/savePayrollSummaryDetails', {payrollData:payroll,storeId:StoreId,periodId:PeriodId}).map((response: Response) => response.json());
}

updateGeneralEntry( payroll: any,storeId:string,periodId:number) {
  let data={"payrollData":payroll,"storeId":storeId,"periodId":periodId};
  return this.httpService.postRequest('API/Poll/UpdateGeneralEntry', data).map((response: Response) => response.json());
}

ApproveDistrictPayrollWaitingforApproval( payroll: any,storeId:string,periodId:number) {
  let data={"payrollData":payroll,"storeId":storeId,"periodId":periodId};
  return this.httpService.postRequest('API/Poll/ApproveDistrictPayrollWaitingforApproval', data).map((response: Response) => response.json());
}
uploadFileOpenEntry( formData:FormData) {
  
  return this.httpService.postRequestWithFile('API/Poll/uploadFileOpenEntry', formData).map((response: Response) => response.json());
}
  
updateWorkRecordStatus( clocksetsId:string ,rowID:number, storeId:string, statusID:number, exceptionTypeId:number) {
  return this.httpService.postRequest('API/Poll/UpdateWorkRecordStatus', {WORK_clockets_ID:clocksetsId,Row_ID:rowID,StoreID:storeId,RecordStatus_ID:statusID,ExceptionTypeID:exceptionTypeId,ModifiedBy:"TestAPI"}).map((response: Response) => response.json());
}

  updatePayRollEntry( payroll: any,StoreId:string, PeriodId:number) {
    return this.httpService.postRequest('API/Poll/UpdateWorkPayrollEntry', {payrollData:payroll,StoreId:StoreId,PeriodId:PeriodId}).map((response: Response) => response.json());
}



  getPollFilePayPeriods(storeId: any, periodId: number) {
    return this.httpService.postRequest('api/Poll/GetPollFilePayPeriods', {
      storeId: storeId,
      periodId: 0,
    });
  }


  getEmployeeLoggedDetail(employeeId: string, importHeaderId: number , shiftDate:Date , ssn:string) {
    return this.httpService.postRequest('api/Poll/GetEmployeeLoggedDetail', {
      employeeId: employeeId,
      importHeaderId: importHeaderId,
      shiftDate: shiftDate ,
      ssn:ssn
    });
  }
  getEmployeeLoggedSummaryDetail(storeId: string, employeeId: string, importHeaderId: number , ssn:string,periodId:number,Role:string) {
    return this.httpService.postRequest('api/Poll/GetEmployeeLoggedSummaryDetail', {
      storeId: storeId, 
      employeeId: employeeId,
      importHeaderId: importHeaderId,
      ssn:ssn,
      periodId:periodId,
      Role:Role
    });
  }
  getReportBaseURL()
    {
        return this.httpService.reportBaseURL;
    }
    getServerBaseURL()
    {
      return this.httpService.baseUrl;
    }
    getReportURLExpression()
    {
      return this.httpService.reportURLExpression;
    }
    getReportAutoLogin()
    {
      return this.httpService.ReportAutoLogin;
    }

}
