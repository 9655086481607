import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService } from '../../service/index'
import { User, Store, UserRole , WorkPayrollModal } from '../../datamodal/index';
import { INTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic/src/platform_providers';
import { PayrollStatus, UserPriviledge } from '../../enum/enum';

@Component({
    selector: 'payrollrejection.dialog',
    templateUrl: 'payrollrejection.dialog.html',
    styleUrls: ['payrollrejection.dialog.scss']
})
export class PayrollRejectionDialog {

    workPayroll: WorkPayrollModal;
    form: FormGroup;
    statusCommentFormControl = new FormControl('', [Validators.required]);

    private validateRequired(): boolean {
        if (!this.statusCommentFormControl.invalid.valueOf()
        ) {
            return true;
        }
        else
            return false;
    }

    private validateUnSaved(): boolean {
        if (!this.statusCommentFormControl.dirty
        ) {
            return true;
        }
        else
            return false;
    }

    message: string;
    currentUser: User;
    saveToModel: User;
   
    isHidden:boolean ;


    constructor(private userService: UserService,
        private alertService: AlertService,
        public dialogRef: MatDialogRef<PayrollRejectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
debugger;
        this.saveToModel = data.user;

        this.workPayroll = Object.assign({}, data.user);

        if(this.workPayroll.payRoleStatusID === PayrollStatus.Rejected)
            this.isHidden = true;
       else
            this.isHidden = false;

    }

    onOkClick(): void {
        if (this.validateRequired()) {
            Object.assign(this.saveToModel, this.workPayroll);
            this.message = "";
            this.dialogRef.close(this.workPayroll);
        } else {
           // this.message = "Please fill comment.";
        }
        
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

