import { Component, Input, Inject, EventEmitter } from '@angular/core';
import { UserService } from '../../service/index'
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PollFileModel } from '../../datamodal/pollfileModal'


@Component({
    selector: 'uploadopensubmitpopup.component',
    templateUrl: 'uploadopensubmitpopup.component.html',
    styleUrls: ['uploadopensubmitpopup.component.scss'],
})
export class UploadOpenSubmitPopup {
    files: File;
    message: string;
    rowData: PollFileModel;
    onClkUpload = new EventEmitter();

    constructor(private userService: UserService,
        public dialogRef: MatDialogRef<UploadOpenSubmitPopup>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.rowData = data.rowData;
    }

    ngOnInit() {
    }

    handleFileInput(event: any) {
        let inputFile:File=<File>event.target.files[0];
        if (inputFile.type == "application/pdf") {
            this.files = <File>event.target.files[0];
            this.message = "";
        } else {
            this.message = "Please select the PDF file.";
        }
      
    }
    clkUploadFile() {
        if (this.files) {
            if (this.files.type == "application/pdf") {
                this.onClkUpload.emit({ rowData: this.rowData, file: this.files });
            } else {
                this.message = "Please select the PDF file.";
            }
        }
        else {
            this.message = "Please select the PDF file.";
        }
    }
    clkCloseFileUpload() {
        this.dialogRef.close();
    }


}