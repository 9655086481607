import { Injectable } from '@angular/core';
import { Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from './http.service';
import { User } from '../datamodal/index';

@Injectable()
export class AuthenticationService {
    constructor(
        private httpService: HttpService,
        private router: Router) { }

    login( user: User) {
        // const body = {UserId: '0', UserName:username, Password:password};
     return  this.httpService.postRequest('api/Account/LoginUser', user)
        .map((response: Response)=>{
            // login successful if there's a jwt token in the response
            let userDetail = response.json();

            if (userDetail) {
                userDetail.password=user.password;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(userDetail));
                // Instead store in service.
            }
        });
    }

    getCurrentCompanyLogo() {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        if(user){
            return this.httpService.postRequest('api/Account/GetUserCompanyLogo', user);
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
    passwordResetRequest( EmailAddress: string) {
     return  this.httpService.postRequest('api/Account/passwordResetRequest', EmailAddress);
    }
    ChangePasswordRequest(EmailAddress:string,Password:string,ResetPasswordValue:string){
        let data:any={"UserName":EmailAddress,"Password":Password,"ResetPasswordValue":ResetPasswordValue};
        return  this.httpService.postRequest('api/Account/ChangePasswordRequest', data);
    
    }
    ValidatePasswordResetRequest(ResetPasswordValue:string){
        return  this.httpService.postRequest('api/Account/ValidatePasswordResetRequest', ResetPasswordValue);
    }
    reCaptchaSiteKeyValue()
    {
        return this.httpService.siteKey;
    }
    getAppVersion(){
        return this.httpService.appVersion;
    }
    getVersionUpdateRefreshTime()
    {
        return this.httpService.VersionUpdateRefreshTime;
    }
}