import { Injectable } from '@angular/core';

  @Injectable()
  export class SelectedPayPeriodModel {
     periodId: number;
     payPeriod?: string;
     periodStartDate: Date;
     periodEndDate: Date;
     payRoleLatestStatus:string;
    }
