import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { AlertService, UserService, SidebarLocatorService, } from '../../service/index';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User, UserBaseModel, UserRole, WorkPayrollModal, PollFileBaseModel,FileStoreModel, PollFileModel, PollFilePeriodModel, PollFileStoreModel } from '../../datamodal/index'
import { PollfileService } from '../../service/pollfile.service';
import { ConfirmDialog } from '../../dialogs/confirm.dialog';
import { EmployeeDetailDialog } from '../../dialogs/employeedetail/employeedetail.dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    moduleId: module.id,
    templateUrl: 'payrollreport.component.html',
    styleUrls: ['payrollreport.component.scss']
})

export class PayrollreportComponent {
    model: any = {};
    batchist: WorkPayrollModal[] = [];
    approvedDataSource: MatTableDataSource<PollFileModel>;
    loading = false;
    public currentUser: User;
    approvedDisplayedColumns = ['storeId', 'employeeName', 'workDate', 'hoursWorked', 'detail', 'exceptionType'];
    dataSource: MatTableDataSource<User>;
    userBaseModel: UserBaseModel[];
    saveMessage: string;
    messageType: string;
    approvedPollbaseModel: PollFileBaseModel;
    approvedPollfileData: PollFileModel[] = [];
    userfileData: User[] = [];
    roleList: UserRole[] = [];
    filtereduserList: UserRole[] = [];
    selectedBatchNo: number;

    periodList: PollFilePeriodModel[] = [];
    storeList: FileStoreModel[]=[];
    selectedUserList: User[] = [];
    showAdminStore: boolean = false;
    showUserStore: boolean = false;
    selectedStoreId: number;
    selectedPeriodId: number;
    selectedStoreNumber: number;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private router: Router,
        private _data: PollfileService,
        private sidebarService: SidebarLocatorService,
        private userService: UserService,
        public dialog: MatDialog,
        private alertService: AlertService, private spinner: NgxSpinnerService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
     //   this.loadBatchNumber();


        this.selectedUserList = JSON.parse(localStorage.getItem('currentUser'));

        if (this.currentUser[0].roleId === 4) {
            this.showAdminStore = true;
            this.showUserStore = false;
            this.loadStores();
        } else {
            this.showAdminStore = false;
            this.showUserStore = true;
            var storeId = parseInt(this.currentUser[0].storeId);
            this.selectedStoreId = storeId;
        }

    }

    loadStores() {
        this.spinner.show();
        this.userService.getStore().subscribe(
            data => {
                this.storeList = data;
                if (this.storeList.length >= 0) {
                    var storeId = this.storeList[0].storeId;
                    this.selectedStoreNumber = storeId;
                    this.spinner.hide();
                }
            },
            error => {
                this.alertService.error(error);
                this.spinner.hide();
            });
    }

    private getPollFilePayPeriods(storeId: number, payPeriodId: number) {
        
        this._data.getPollFilePayPeriods(storeId, payPeriodId).subscribe(result => {
            const obj = result.json();
            //this.pollfileLookUp = obj;
            this.periodList = obj;
            if (this.periodList.length > 0) {
                var periodId = this.periodList[0].periodId;
                this.selectedPeriodId = periodId;
               // this.spinner.hide();
                this.loadBatchNumber()
            }
           
        }, error => {
            this.alertService.error(error);
            this.spinner.hide();
        });
    }

    payPeriodfilterChanged(){
        this.spinner.show();
      
            this.loadBatchNumber();
             this.approvedDataSource.data =[];
           
    }

    loadBatchNumber() {
        this._data.getBatchNumber(this.selectedStoreNumber , this.selectedPeriodId).subscribe(
            data => {
                this.batchist = data;
                if (this.batchist.length > 0) {
                    var batchNo = parseInt(this.batchist[0].bachnumb);
                    this.selectedBatchNo = batchNo;
                    this.spinner.hide();
                    this.onSearch()
                }else{
                    this.spinner.hide();
                }
              
              //  this.onSearch()
            },
            error => {
                this.alertService.error(error);
                this.spinner.hide();
            });
    }

    filterBatchChanged(){

        this.spinner.show();
        this._data.getStoreIdFromBachNo(this.selectedBatchNo,  this.currentUser).subscribe(
            data => {
                this.storeList = data  ;
                if (this.storeList.length >= 0) {
                    var storeId = this.storeList[0].storeId;
                    this.selectedStoreNumber = storeId;
                    this.getPollFilePayPeriods(storeId, 0);
                }else{
                    this.storeList=[];
                    this.selectedStoreNumber = 0;
                    this.selectedPeriodId = 0;
                    this.spinner.hide();
                }
            },
            error => {
                this.alertService.error(error);
                this.spinner.hide();
            });
    }

    
    filterChanged() {
        this.spinner.show();
        this.selectedPeriodId = 0;
        this.getPollFilePayPeriods(this.selectedStoreNumber, this.selectedPeriodId)
    }

    onSearch() {
     
        this.spinner.show();
        this._data.getPollFileFromBachNo(this.selectedBatchNo, this.selectedStoreNumber, this.selectedPeriodId, this.currentUser).subscribe(result => {
            const obj = result.json();
            this.approvedPollbaseModel = obj;
            this.approvedPollfileData = this.approvedPollbaseModel.reviewData;

         
            // Assign the data to the data source for the table to render
            this.approvedDataSource = new MatTableDataSource(this.approvedPollfileData);
            this.approvedDataSource.paginator = this.paginator;
            this.approvedDataSource.sort = this.sort;
            this.spinner.hide();
        }, error => {
            this.alertService.error(error);
            this.spinner.hide();
        });
    }


    showLoggedDetail(item: any) {
        var list;
        this._data.getEmployeeLoggedDetail(item.employeeID, item.importHeaderId, item.workDate , item.employeeID).subscribe(result => {

            const obj = result.json();
            list = obj;
            let userDialogRef = this.dialog.open(EmployeeDetailDialog, {
                width: '550px',
                height: '630px',
                data: {
                    title: 'Employee Time Details',
                    user: list,
                    importHeaderId: item.importHeaderId,
                    employeeName: item.employeeName,
                    shiftDate: item.workDate,
                    exceptionType: item.exceptionType
                }
            });
           
            userDialogRef.afterClosed().subscribe(result => {
                if (result != undefined) {
                    let userModel = new User;
                    // Update info to push in database.
                    this.userService.updateRequestSiteAccess(item)
                        .subscribe(
                            data => {

                            },
                            error => {
                                this.alertService.error(error);
                            });
                }
            });
        });
    }


   

}
