//export enum PayrollStatus {
//    Open = 0,
//    Approved = 1,
//    Rejected = 2,
//    Removed = 3,
//    Submitting=4,
//    WaitingApproval=5
//}

export enum PayrollStatus {
    Open = 1,
    Approved = 4,
    Rejected = 5,
    Removed = 6,
    Submitting=2,
    WaitingApproval=3
}


export enum UserPriviledge {
    GeneralManager = 1,
    DistrictManager = 2,
    PayrollManager = 3,
    Administrator = 4,
}

