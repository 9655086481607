export class PollEmployeeWork{
    storeId:string;
    clockIn :string;
    clockOut :string;
    shiftMinutes:string;
    employeeId :string;
    importHeaderId:string;
    ShiftDate:Date;
    isStoreEntryOverlap :Boolean;
    isselectedImportHeaderId:boolean;
    break:Number;
    isMealViolation:boolean;
    columnClass:string;
    exceptionType:string;
    payRoleStatusID:number;
    isMPVWaiverFound:boolean;
    }
    
export class detailsPollEmployeeWork{
    shiftDate:Date;
    storeId:string;
    clockIn :string;
    clockOut :string;
    shiftMinutes:string;
    pollEmployeeWork:Array<PollEmployeeWork>;
    dailyHoursWorked:string;
}