import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpService } from './http.service';
import { User, EntityStore } from '../datamodal/index';

@Injectable()
export class UserService {
    constructor(private http: Http , private httpService: HttpService) { }


    saveRequestSiteAccess( user: any) {
        let model =user;
        return this.httpService.postRequest('API/Account/SaveRequestSiteAccess', model).map((response: Response) => response.json());
    }

    updateRequestSiteAccess( user: any) {
        let model =user;
        return this.httpService.postRequest('API/Account/UpdateRequestSiteAccess', model).map((response: Response) => response.json());
    }
    
    deleteUser(user: User) {
        let model =user;
        return this.httpService.postRequest("API/Account/DeleteUser",model).map((response: Response) => response.json());
    }

    changePassword(UserName:string,Password:string) {
        let model ={UserName:UserName,Password:Password};
        return this.httpService.postRequest("API/Account/ChangePassword",model).map((response: Response) => response.json());
    }

    getUserDetails(){
        let model ={};
        return this.httpService.postRequest('API/Account/GetRequestSiteAccess', model).map((response: Response) => response.json());

    }

    disableStore(entityStore: EntityStore) {
        let model =entityStore;
        return this.httpService.postRequest("API/Account/DisableStore",model).map((response: Response) => response.json());
    }

    activateStore(entityStore: EntityStore) {
        let model =entityStore;
        return this.httpService.postRequest("API/Account/ActivateStore",model).map((response: Response) => response.json());
    }



    getStoreDetails(){
        let model ={};
        return this.httpService.postRequest('API/Account/GetStoresForMaint', model).map((response: Response) => response.json());

    }

    getRole(){
        let model ={};
        return this.httpService.postRequest('API/Account/GetRole', model).map((response: Response) => response.json());
    }
    getStoreByPeriodId(periodId:number) {
        return this.httpService.postRequest('API/Account/getStoreByPeriodId', periodId).map((response: Response) => response.json());
    }
    StoreByPeriodIdAndUser(periodId:number,UserName:string)
    {
        let model ={PeriodId:periodId,UserName:UserName};
        return this.httpService.postRequest('API/Account/StoreByPeriodIdAndUser ', model).map((response: Response) => response.json());
    }
    GetUserDetailsByUsername(UserName:string)
    {
        let model ={UserName:UserName};
        return this.httpService.postRequest('API/Account/GetUserDetailsByUsername ', model).map((response: Response) => response.json());
    }
    getStore() {
        let model ={};
        return this.httpService.postRequest('API/Account/GetStore', model).map((response: Response) => response.json());
    }

    getStoresForUser(storeId : string, userName:string) {
         const body = {
            storeId: storeId ,
            userName : userName
        };
        return this.httpService.postRequest('API/Account/GetStoreDetail', body).map((response: Response) => response.json());
    }

    getAll() {
        return this.http.get('/api/users', this.jwt()).map((response: Response) => response.json());
    }

    getById(id: number) {
        return this.http.get('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
    }

    create(user: User) {
        return this.http.post('/api/users', user, this.jwt()).map((response: Response) => response.json());
    }

    update(user: User) {
        return this.http.put('/api/users/' + user.id, user, this.jwt()).map((response: Response) => response.json());
    }

    delete(id: number) {
        return this.http.delete('/api/users/' + id, this.jwt()).map((response: Response) => response.json());
    }


    private basePost(body: any, url: any) {
        return this.httpService.postRequest(url, body).map((response: Response) => response.json());
    }


    // private helper methods

    private jwt() {
        // create authorization header with jwt token
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            const headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
            return new RequestOptions({ headers: headers });
        }
    }
}