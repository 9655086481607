export class User{
id:number;
fullName:string;
username:string;
address:string;
phoneNumber:number;
storeId:string;
storeName:string;
isActive:boolean;
isApproved:boolean;
isPasswordChanged:boolean;
password: string;
confirmPassword:string;
salt: string;
roleId:number;
createdDate:Date;
modifiedDate:Date;
isDeleted:boolean;
}

export class UserBaseModel {
    reviewData: User[];
  }
