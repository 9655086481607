import { Component, Inject, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { User, PollFilePeriodModel } from '../datamodal/index';
import { SelectedPayPeriodModel } from '../datamodal/payperiodmodal';
import { PollfileService } from '../service/pollfile.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'app-sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    @Input() public itemList: [any, any][];
    @Output() OnClicked = new EventEmitter<any>();

    private selectedItem: any;
    private filterString: string;
    private filterLambda = (x) => x[0];

    public currentUser: User;
    public dataPollFilePeriodModel: PollFilePeriodModel;

    constructor(private _pollfileService: PollfileService, private spinner: NgxSpinnerService, private selectedPayPeriodModel: SelectedPayPeriodModel) {
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (this.currentUser[0].roleId === 4) {
            const menuItems = [['Payroll', '/payroll/timesheet'],
            // ['Payroll Report', '/report/payroll'] ,
            // ['User Maintenance', '/maintenance/usermaintenance']
            ];

            //   const menuItems = [['User Maintenance', '/maintenance/usermaintenance']];

            this.ParseList(menuItems, x => x[0], x => x[1]);
            //  this.LinkClicked(menuItems[0][1]);

        } else {

            const menuItems = [['Payroll', '/payroll/timesheet'],
                // ['Payroll Report', '/report/payroll']

            ];
            //   const menuItems = [];

            this.ParseList(menuItems, x => x[0], x => x[1]);
            //  this.LinkClicked(menuItems[0][1]);
        }
        //this.getPollFilePayPeriods();


    }
    //   clkPollFilePeriod(data:PollFilePeriodModel){
    //     this.selectedPayPeriodModel.payPeriod=data.payPeriod;
    //     this.selectedPayPeriodModel.payRoleLatestStatus=data.payRoleLatestStatus;
    //     this.selectedPayPeriodModel.periodStartDate=data.periodStartDate;
    //     this.selectedPayPeriodModel.periodEndDate=data.periodEndDate;
    //     this.selectedPayPeriodModel.periodId=data.periodId;
    //     this.selectedItem =  data.periodId;
    //     this.OnClicked.emit("/payroll/timesheet");
    // }


    LinkClicked(obj) {
        this.selectedItem = obj;
        this.OnClicked.emit(obj);
    }

    ParseList<T>(list: T[], getKey: (T) => string, getValue: (T) => number) {
        this.Clear();
        for (let i = 0; i < list.length; i++) {
            this.Append<T>(list[i], getKey, getValue);
        }
        if (this.itemList.length > 0) {
            this.selectedItem = this.itemList[0][1];
        }
    }

    Append<T>(item: T, getKey: (T) => string, getValue: (T) => number) {
        this.itemList[this.itemList.length] = [getKey(item), getValue(item)];
    }

    Clear() {
        this.itemList = [];
    }

    GetSelectedValue() {
        if (this.selectedItem) {
            return this.selectedItem;
        } else {
            return null;
        }
    }

    GetSelectedKey() {
        if (this.selectedItem) {
            const index = this.itemList.findIndex(x => x[1] === this.selectedItem);
            if (index >= 0) {
                return this.itemList[index][0];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    @Input()
    get filter(): string {
        return this.filterString;
    }

    set filter(filter: string) {
        this.filterString = filter;
    }

    get lambda(): (any) => string {
        return this.filterLambda;
    }

    clearFilter() {
        this.filterString = '';
    }

    private isActive(item: any) {
        return this.selectedItem && item == this.selectedItem;
    }

    //   private getPollFilePayPeriods() {
    //     this.spinner.show();
    //     this._pollfileService.getPollFilePayPeriods(0,0).subscribe(result => {
    //       const obj = result.json();
    //       this.dataPollFilePeriodModel = obj;
    //       this.selectedPayPeriodModel.payPeriod=this.dataPollFilePeriodModel[0].payPeriod;
    //       this.selectedPayPeriodModel.payRoleLatestStatus=this.dataPollFilePeriodModel[0].payRoleLatestStatus;
    //       this.selectedPayPeriodModel.periodStartDate=this.dataPollFilePeriodModel[0].periodStartDate;
    //       this.selectedPayPeriodModel.periodEndDate=this.dataPollFilePeriodModel[0].periodEndDate;
    //       this.selectedPayPeriodModel.periodId=this.dataPollFilePeriodModel[0].periodId;
    //       this.spinner.hide();
    //     });
    //   }
}


