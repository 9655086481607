import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { AlertService, UserService, SidebarLocatorService } from '../service/index';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EntityStore, User, EntityStoreBaseModel, UserRole } from '../datamodal/index'
import { RequestAccessSiteDialog } from '../dialogs/requestaccesssite/requestaccesssite.dialog';
import { ConfirmDialog } from '../dialogs/confirm.dialog';
import { AddUserPopup } from '../dialogs/adduserpopup/adduserpopup.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    moduleId: module.id,
    templateUrl: 'storemaintenance.component.html',
    styleUrls: ['storemaintenance.component.css']
})

export class StoreMaintenanceComponent {
    model: any = {};
    loading = false;
    public currentUser: User;
    displayedColumns = ['storeName', 'storeStatus', 'action2'];
    dataSource: MatTableDataSource<EntityStore>;
    userBaseModel: EntityStoreBaseModel[];
    userMaintenanceList: User[];
    storeMaintenanceList: EntityStore[];
    saveMessage: string;
    messageType: string;
    userfileData: User[] = [];
    roleList: UserRole[] = [];
    filtereduserList: UserRole[] = [];
    filterKeyValue: string;
    // SearchIsNotApprove: boolean = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private router: Router,
        private sidebarService: SidebarLocatorService,
        private userService: UserService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.getList();
    }

    private getList() {
        this.spinner.show();
        this.userService.getStoreDetails().subscribe(result => {
            const obj = result;

            this.storeMaintenanceList = obj;
            this.userBaseModel = obj;
            // this.userfileData = this.userBaseModel.reviewData;
            // Assign the data to the data source for the table to render
            this.filterKeyValue = this.filterKeyValue ? this.filterKeyValue : "";
            this.applyFilter(this.filterKeyValue);
            this.spinner.hide();
            // this.dataSource = new MatTableDataSource(obj);
            // this.dataSource.paginator = this.paginator;
            // this.dataSource.sort = this.sort;
            // this.chkSearchIsNotApprove();
        })
    }

    applyFilter(filterValue: string) {
        this.dataSource = new MatTableDataSource(this.storeMaintenanceList.filter(x => x.homeDepartmentCode.toLowerCase().indexOf(filterValue.toLowerCase()) != -1));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    disableDialog(entityStore: EntityStore): void {
        var homeDepartmentCode = entityStore.homeDepartmentCode;
        let confirmDialogRef = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: { title: `Are you sure , you want to Disable the store ${homeDepartmentCode}?`, entityStore: entityStore }
        });

        confirmDialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {

                this.spinner.show();
                this.userService.disableStore(entityStore).subscribe(data => {
                    if (data) {
                        this.saveMessage = "Store disabled successfully.";
                        this.messageType = "success";
                        this.spinner.show();
                        this.getList();

                    }
                    else {
                        this.spinner.show();
                        this.saveMessage = "Store not disabled.";
                        this.messageType = "error";
                    }
                });
            }
        });
    }

    activateDialog(entityStore: EntityStore): void {
        var homeDepartmentCode = entityStore.homeDepartmentCode;
        let confirmDialogRef = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: { title: `Are you sure , you want to Activate the store ${homeDepartmentCode}?`, entityStore: entityStore }
        });

        confirmDialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {

                this.spinner.show();
                this.userService.activateStore(entityStore).subscribe(data => {
                    if (data) {
                        this.saveMessage = "Store activated successfully.";
                        this.messageType = "success";
                        this.spinner.show();
                        this.getList();

                    }
                    else {
                        this.spinner.show();
                        this.saveMessage = "Store not activated.";
                        this.messageType = "error";
                    }
                });
            }
        });
    }

}
