import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { AlertService, UserService, SidebarLocatorService } from '../service/index';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User, UserBaseModel, UserRole } from '../datamodal/index'
import { RequestAccessSiteDialog } from '../dialogs/requestaccesssite/requestaccesssite.dialog';
import { ConfirmDialog } from '../dialogs/confirm.dialog';
import { AddUserPopup } from '../dialogs/adduserpopup/adduserpopup.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    moduleId: module.id,
    templateUrl: 'usermaintenance.component.html',
    styleUrls: ['usermaintenance.component.css']
})

export class UserMaintenanceComponent {
    model: any = {};
    loading = false;
    public currentUser: User;
    displayedColumns = ['userName', 'fullName', 'access', 'action2'];
    dataSource: MatTableDataSource<User>;
    userBaseModel: UserBaseModel[];
    userMaintenanceList: User[];
    saveMessage: string;
    messageType: string;
    userfileData: User[] = [];
    roleList: UserRole[] = [];
    filtereduserList: UserRole[] = [];
    filterKeyValue: string;
    // SearchIsNotApprove: boolean = true;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private router: Router,
        private sidebarService: SidebarLocatorService,
        private userService: UserService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
    ngOnInit() {
        this.getRole();
    }

    getRole() {
        this.spinner.show();
        this.userService.getRole().subscribe(result => {
            const obj = result;
            this.roleList = obj;
            this.spinner.hide();
            this.getList();
        })
    }

    private getList() {
        this.spinner.show();
        this.userService.getUserDetails().subscribe(result => {
            const obj = result;

            obj.forEach(element => {
                var temp = this.roleList.find(x => x.roleId == element.roleId);
                element.defaultPassword = "*******";
                if (temp)
                    element.roleName = temp.roleName;
                else
                    element.roleName = "";

            });
            this.userMaintenanceList = obj;
            this.userBaseModel = obj;
            // this.userfileData = this.userBaseModel.reviewData;
            // Assign the data to the data source for the table to render
            this.filterKeyValue = this.filterKeyValue ? this.filterKeyValue : "";
            this.applyFilter(this.filterKeyValue);
            this.spinner.hide();
            // this.dataSource = new MatTableDataSource(obj);
            // this.dataSource.paginator = this.paginator;
            // this.dataSource.sort = this.sort;
            // this.chkSearchIsNotApprove();
        })
    }

    setModalUserId(userName: string): void{
        document.getElementById('txtConfirmUserName').innerHTML = userName;

        
        (<HTMLInputElement>document.getElementById('txtPassword')).value = "";
        (<HTMLInputElement>document.getElementById('txtPasswordConfirm')).value = "";

    }

    submitChangePassword(){
        var curUserName = document.getElementById('txtConfirmUserName').innerHTML;
        var password = (<HTMLInputElement>document.getElementById('txtPassword')).value;
        var passwordConfirm = (<HTMLInputElement>document.getElementById('txtPasswordConfirm')).value;
        if(password!=passwordConfirm)
        {
            alert('Please confirm the password typed in and try to save again');
            return false;
        }
        if(password.trim().length<8 || password.trim().length>12)
        {
            alert('Please make passwords 8 - 12 characters long');
            return false;
        }

        //userModel.isDeleted = true;
        this.spinner.show();
        this.userService.changePassword(curUserName.trim(),password.trim()).subscribe(data => {
            if (data) {
                this.saveMessage = "User password changed.";
                this.messageType = "success";
                this.spinner.hide();
            }
            else {
                this.spinner.hide();
                this.saveMessage = "User not updated.";
                this.messageType = "error";
            }
        });

        this.closeAllModals();
        //$('#denyCheckModal').modal('hide');
        //$("[data-dismiss=modal]").trigger({ type: "click" });

        return true;
    }

    closeAllModals() {

        // get modals
        const modals = document.getElementsByClassName('modal');
    
        // on every modal change state like in hidden modal
        for(let i=0; i<modals.length; i++) {
          modals[i].classList.remove('show');
          modals[i].setAttribute('aria-hidden', 'true');
          modals[i].setAttribute('style', 'display: none');
        }
    
         // get modal backdrops
         const modalsBackdrops = document.getElementsByClassName('modal-backdrop');
    
         // remove every modal backdrop
         for(let i=0; i<modalsBackdrops.length; i++) {
           document.body.removeChild(modalsBackdrops[i]);
         }
      }

    deleteRequestDialog(user: User): void {
        var storeNo = user.storeId;
        let confirmDialogRef = this.dialog.open(ConfirmDialog, {
            width: '400px',
            data: { title: 'Are you sure , you want to delete this user?', user: user }
        });

        confirmDialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
                let userModel = new User;
                userModel = result.user;
                //userModel.isDeleted = true;
                this.spinner.show();
                this.userService.deleteUser(userModel).subscribe(data => {
                    if (data) {
                        this.saveMessage = "User deleted successfully.";
                        this.messageType = "success";
                        this.spinner.show();
                        this.getList();
                        // let updateItem = this.userBaseModel.find(this.findUserIndexToUpdate, userModel.id);
                        // let index = this.userBaseModel.indexOf(updateItem);
                        // this.userBaseModel.splice(index, 1);
                        // this.filtereduserList.splice(index, 1);
                    }
                    else {
                        this.spinner.show();
                        this.saveMessage = "User not deleted.";
                        this.messageType = "error";
                    }
                });
            }
        });
    }

    findUserIndexToUpdate(newItem) {
        return newItem.id === this;
    }

    editStoreDialog(item: User) {
        let height = window.innerHeight;
        let width = window.innerWidth;
        let userDialogRef = this.dialog.open(AddUserPopup, {
            width: width + "px",
            height: height + "px",
            data: {
                title: 'Update Request Site Access Detail',
                user: item
            }
        });

        userDialogRef.afterClosed().subscribe(result => {
            if (result != undefined) {
                let userModel = new User;
                // Update info to push in database.
                this.userService.updateRequestSiteAccess(item)
                    .subscribe(
                        data => {
                            if (data && data.id > 0) {
                                this.getRole();
                                this.saveMessage = "User access updated successfully.";
                                this.messageType = "success";
                            } else {
                                this.saveMessage = "User access not updated.";
                                this.messageType = "error";
                            }
                            //   this.alertService.success('Registration update successful', true);
                        },
                        error => {
                            this.alertService.error(error);
                        });

            }
        });
    }
    applyFilter(filterValue: string) {
        // this.dataSource = new MatTableDataSource(this.userMaintenanceList.filter(x => x.fullName.toLowerCase().indexOf(filterValue.toLowerCase()) != -1 && x.isApproved != this.SearchIsNotApprove));
        this.dataSource = new MatTableDataSource(this.userMaintenanceList.filter(x => x.fullName.toLowerCase().indexOf(filterValue.toLowerCase()) != -1));
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    // chkSearchIsNotApprove() {
    //     this.dataSource = new MatTableDataSource(this.userMaintenanceList.filter(x => x.isApproved != this.SearchIsNotApprove));
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }
    clkAddUser() {
        let height = window.innerHeight;
        let width = window.innerWidth;
        let AddUserDialogRef = this.dialog.open(AddUserPopup, {
            width: width + "px",
            height: height + "px"
        });
        AddUserDialogRef.afterClosed().subscribe(result => {
                this.getList();
        });
    }
}
