import { Component, OnInit } from '@angular/core';
import { AlertService, AuthenticationService } from '../service/index';
import {ActivatedRoute,Router} from '@angular/router';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  ResetPasswordValue:string; 
  EmailAddress:string;
  Password:string;
  ConfirmPassword:string;

  constructor( private authenticationService: AuthenticationService,
    private alertService: AlertService,private route:ActivatedRoute,private _router:Router) { }

  ngOnInit() {
    this.ResetPasswordValue=this.route.snapshot.queryParams["param1"];
    if(!this.ResetPasswordValue)
    {
      this._router.navigate(['login']);
    }
    this.authenticationService.ValidatePasswordResetRequest(this.ResetPasswordValue) .subscribe(
      data => {
        let varResult:any=data;
        if(varResult._body=="false")
        {
          alert("Invalid Password Reset Key");
          this._router.navigate(['login']);
        }
      },
      error => {
      });
  }

  clkChangePassword()
  {
    if(!this.ResetPasswordValue)
    {
      alert("Password reset key does not exist ");
    }
    if(this.Password==this.ConfirmPassword)
    {
      this.authenticationService.ChangePasswordRequest(this.EmailAddress,this.Password,this.ResetPasswordValue) .subscribe(
      data => {
        // console.log(data);
        alert("Password changed successfully");
        this.ResetPasswordValue=null; 
        this.EmailAddress=null;
        this.Password=null;
        this.ConfirmPassword=null;
        this._router.navigate(['login']);
      },
      error => {
      });
    }else
    {
      alert("Password and confirm password are not matched");
    }
  }
}
