export class EntityStore {
    id: number;
    storeID: number;
    homeDepartmentCode: string;
    storeName: string;
    storeStatus: string;
    isActive:boolean;
  }
  export class EntityStoreBaseModel {
    reviewData: EntityStore[];
  }
  