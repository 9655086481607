import { Component, Input } from '@angular/core';
import { AlertService, UserService } from '../../service/index'
import { PollEmployeeWork } from '../../datamodal/index';
import { MatTableDataSource } from '@angular/material';
@Component({
    selector: 'expanddetails-popup',
    templateUrl: 'expanddetailspopup.component.html',
    styleUrls: ['expanddetailspopup.component.scss'],

})
export class ExpandDetailsPopup {
    displayedColumns = ['storeId', 'shiftDate', 'clockIn', 'clockOut', 'shiftMinutes', 'break'];

    dataSource: MatTableDataSource<PollEmployeeWork>;
    @Input() public inputData: Array<PollEmployeeWork>;
    @Input() public importHeaderId: string;


    constructor(private userService: UserService,
        private alertService: AlertService) {

    }
    // ngOnInit() {
    //     this.dataSource=new MatTableDataSource(this.inputData);
    // }
    ngOnInit() {
        this.inputData.forEach(element => {
            if (element.importHeaderId === this.importHeaderId) {
                element.isselectedImportHeaderId = true; //Show yellow color
            }
            else if (element.isStoreEntryOverlap === true) {
                element.isStoreEntryOverlap = true; //Show Red color
            }
            // element.shiftMinutes = this.display(element.shiftMinutes);
            // if (element.isStoreEntryOverlap) {
            //     element.columnClass = '#ff9999';
            // } else if (element.isMealViolation) {
            //     element.columnClass = 'yellow';
            // } else {
            //     element.columnClass = 'white';
            // }
            // if (element.exceptionType.trim().toLowerCase() == "clean") {
            //     element.columnClass = "#33cc33";//
            // }
            // else if (element.payRoleStatusID == 0) {
            //     element.columnClass = "#ff9999";
            // }
            // else if (element.payRoleStatusID == 1) {
            //     element.columnClass = "#ff9900";
            // }

        });

        var highlightedList = this.inputData.filter(x => x.importHeaderId === this.importHeaderId)
        var list = this.inputData.filter(x => x.importHeaderId !== this.importHeaderId)

        //pushing highlightedlist and list in new array for displaying yellow and white in grid
        var result = [];
        highlightedList.forEach(item => {
            result.push(item);
        });
        list.forEach(data => {
            result.push(data);
        });

        this.dataSource = new MatTableDataSource(result);
    }



}

