import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService } from '../../service/index'
import { User, Store, UserRole } from '../../datamodal/index';

@Component({
    selector: 'requestaccesssite.dialog',
    templateUrl: 'requestaccesssite.dialog.html',
    styleUrls: ['requestaccesssite.dialog.scss']
})
export class RequestAccessSiteDialog {
    passwordMatch: boolean;
    userModel: User;
    // signUpForm: FormGroup;

    form: FormGroup;
    fullNameFormControl = new FormControl('', [Validators.required]);
    userNameFormControl = new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]);
    addressFormControl = new FormControl('', [Validators.required]);
    phoneNumberFormControl = new FormControl('', [Validators.required]);
    storeFormControl = new FormControl('', [Validators.required]);
    passwordFormControl = new FormControl('', [Validators.required]);
    confirmPasswordFormControl = new FormControl('', [Validators.required]);
    roleFormControl = new FormControl('', [Validators.required]);

    private validateRequired(): boolean {
        if (!this.fullNameFormControl.invalid.valueOf()
            && !this.userNameFormControl.invalid.valueOf()
            && !this.addressFormControl.invalid.valueOf()
            && !this.phoneNumberFormControl.invalid.valueOf()
            && !this.storeFormControl.invalid.valueOf()
            && !this.passwordFormControl.invalid.valueOf()
            && !this.confirmPasswordFormControl.invalid.valueOf()
            && !this.roleFormControl.invalid.valueOf()
        ) {
            return true;
        }
        else
            return false;
    }

    private validateUnSaved(): boolean {
        if (!this.fullNameFormControl.dirty
            && !this.userNameFormControl.dirty
            && !this.addressFormControl.dirty
            && !this.phoneNumberFormControl.dirty
            && !this.storeFormControl.dirty
            && !this.passwordFormControl.dirty
            && !this.confirmPasswordFormControl.dirty
            && !this.roleFormControl.dirty
        ) {
            return true;
        }
        else
            return false;
    }

    roleList: UserRole[] = [];
    message: string;
    currentUser: User;
    saveToModel: User;

    constructor(private userService: UserService,
        private alertService: AlertService,
        public dialogRef: MatDialogRef<RequestAccessSiteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.saveToModel = data.user;
        this.userModel = Object.assign({}, data.user);
        this.userModel.password = "password";
        this.userModel.confirmPassword = this.userModel.password;
        this.userModel.isPasswordChanged = false;
        this.getRole();
    }


    changePassword() {
        this.userModel.isPasswordChanged = true;
    }

    getRole() {
        this.userService.getRole().subscribe(result => {
            const obj = result;
            this.roleList = obj;
        })
    }

    passwordsMatch(password: string, confirmedPassword: string) {
        if (password !== confirmedPassword) {
            return false;
        } else {
            return true;
        }
    }


    

    onOkClick(): void {

        if (this.validateRequired()) {
            if (this.userModel.roleId !== null) {
                this.passwordMatch = this.passwordsMatch(this.userModel.password, this.userModel.confirmPassword)
                if (this.passwordMatch === false) {
                    this.message = " Password doesn't match.";
                } else {
                    this.userService.updateRequestSiteAccess(this.userModel)
                        .subscribe(
                            data => {
                                this.alertService.success('Registration update successful', true);
                            },
                            error => {
                                this.alertService.error(error);
                            });

                    Object.assign(this.saveToModel, this.userModel);
                    this.message = "";
                    this.dialogRef.close(this.data);
                }
            } else {
                this.message = " Please select Roles.";
            }
        } else {
            this.message = "Please provide all the required info.";
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

