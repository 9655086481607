import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { User } from '../datamodal/index';
import { AuthenticationService, SidebarLocatorService } from '../service/index';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-report-shell',
    templateUrl: './report-shell.component.html',
    styleUrls: ['./report-shell.component.scss']
})
export class ReportShellComponent implements OnInit {
    @ViewChild('sidebar') sidebar: SidebarComponent;

    public currentUser: User;
    public logoData = '';


    constructor(private router: Router, private authenicationServie: AuthenticationService,
        private sidebarService: SidebarLocatorService, private spinner: NgxSpinnerService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

        // this.authenicationServie.getCurrentCompanyLogo().subscribe(response => {
        //   this.logoData = 'data:image/jpg;base64,' + response.json();
        // });

    }

    ngOnInit() {

    }

    onLoader(event: any) {
        if (event === "true") {
            this.spinner.show();
        } else {
            this.spinner.hide();
        }
    }

    SwitchSubpage(path: string) {
        this.router.navigate([path]);
    }

    filterItem() { }

    ItemSelected(obj) {

    }
}