import { Component, Inject } from '@angular/core';
import {  UserService } from '../../service/index'
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import{RegisterComponent} from '../../register/register.component';
import { User, Store, UserRole } from '../../datamodal/index';

@Component({
    selector: 'adduserpopup.component',
    templateUrl: 'adduserpopup.component.html',
    styleUrls: ['adduserpopup.component.scss'],
    // directives: [RegisterComponent]
})
export class AddUserPopup {
    userModel: User;

public user:string='Admin';

    constructor(private userService: UserService,public dialogRef: MatDialogRef<AddUserPopup>, @Inject(MAT_DIALOG_DATA) public data: any ) {

        if(data !=null && data.user!=null)
        {
            this.userModel = Object.assign({}, data.user);
        }
    }

    ngOnInit() {

    }
    onNoClick(): void {
        this.dialogRef.close();
    }
    clkClosePopup()
    {
        this.dialogRef.close();
    }
}