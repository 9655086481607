import { Component, OnInit } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AuthenticationService } from '../service/index';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
  model: any = {};
  ForgetPasswordCaptchaValue:any;
  EmailAddress:string;
  passwordResetRequest:boolean=false;
  reCaptchaSiteKey:string;
  constructor(
        // private route: ActivatedRoute,
        // private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService)
  { 
  }

  ngOnInit() {
    this.authenticationService.reCaptchaSiteKeyValue().subscribe(
      data => {
        this.reCaptchaSiteKey=data;
      },
      error => {
      });
  }

  clkForgetPassword(){
    this.passwordResetRequest=false;
    if(this.ForgetPasswordCaptchaValue)
    {
      this.authenticationService.passwordResetRequest(this.EmailAddress) .subscribe(
        data => {
          this.passwordResetRequest=true;
        },
        error => {
        });
    }else{
      alert("Please enter i am not robot confirmation")
    }
  }

}
