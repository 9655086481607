export class PollFileBaseModel {
  reviewData: PollFileModel[];
}
export class PollFileModel {
  payrollEntryID: number;
   storeId: string;
  employeeName: string;
  hoursWorked: number;
  workDate: Date;
  exceptionType: string;
  isSelected: boolean;
  payRoleStatusID:number;
  statusComment:string;
  isNoteAvailable:boolean;
  payRate1:string;
  payRate2:string;
  payRate3:string;
  ssn:string;
  fileNumber:string;
  checkedApproved:boolean;
  checkedRejected:boolean;
  clocksetsId:string;
  rowID:number;
 exceptionTypeID:number;

  importHeaderId: number;
  employeeID:string;

  isPayRateDifferent:boolean;
  isMealWaiverOnFile:boolean;
  regularHours:number;
  overTimeHours:number;
  doubleTimeHours:number;
  regularPayRateAmount:number;
  homeDepartmentCode:string;
  sickTime:number;
  vacationTime:number;
  uploadedFileName:string;
  isMPVWaiverFound:boolean;
  paidHoursWorked:number;
  paidUnpaidHoursWorked:number;
  isApproved:boolean;
  premiumPaidHours:number;
  clocksetsHours:number;
  isSuggested:boolean;
  sickBalance:number;
}

export class PollFileSummaryBaseModel {
  reviewData: PollFileSummaryModel[];
}

export class PollFileSummaryModel {
  payrollEntryID: number;
   storeId: string;
  employeeName: string;
  hoursWorked: number;
  workDate: Date;
  exceptionType: string;
  isSelected: boolean;
  payRoleStatusID:number;
  statusComment:string;
  isNoteAvailable:boolean;
  payRate1:string;
  payRate2:string;
  payRate3:string;
  ssn:string;
  fileNumber:string;
  checkedApproved:boolean;
  checkedRejected:boolean;
  clocksetsId:string;
  rowID:number;

  importHeaderId: number;
  employeeID:string;

  isPayRateDifferent:boolean;
  isMealWaiverOnFile:boolean;
  regularHours:number;
  overTimeHours:number;
  doubleTimeHours:number;
  regularPayRateAmount:number;
  homeDepartmentCode:string;
  sickTime:number;
  vacationTime:number;
  uploadedFileName:string;
  isMPVWaiverFound:boolean;
  paidHoursWorked:number;
  paidUnpaidHoursWorked:number;
  isApproved:boolean;
  premiumPaidHours:number;
  clocksetsHours:number;
  isSuggested:boolean;
  sickBalance:number;
}

export class PollFilePeriodModel {
  periodId: number
  payPeriod: string;
  periodStartDate: Date;
  periodEndDate: Date;
  payRoleLatestStatus:string;
  selectedPeriod:number
}
export class PollFileStoreModel {
  storeId: string;
  periodId:number;
  periodStartDate:Date;
  periodEndDate:Date;
  payRoleLatestStatus:string;
}
export class PollFileStoreModelWithUser {
  storeId: string;
  periodId:number;
  periodStartDate:Date;
  periodEndDate:Date;
  payRoleLatestStatus:string;
  userName:string;
  roleId:number;
}


export class FileStoreModel {
  storeId: number;
}

export class PollFileLookUpModel {
  periods: PollFilePeriodModel[];
  stores: PollFileStoreModel[];
}

export class PollNewFileBaseModel {
  reviewData: PollNewFileModel[];
}
export class PollNewFileModel {
  employeeID:string;
  employeeName: string;
  hoursWorked:number
  importHeaderId: number;
  storeId: string;
  payRate:string;
}