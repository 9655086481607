import { Component, Output, EventEmitter, Input } from '@angular/core';
import { User } from '../datamodal/user';
import { AuthenticationService } from '../service/index';
import { PayrollStatus, UserPriviledge } from '../enum/enum';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {

  @Output() public filterChange = new EventEmitter<string>();
  public filterString: string;
  @Input() public currentUser: User;
  @Input() logo = '';
  @Input() Active = '';
  @Input() title = '';
  public appVersion: string;
  public VersionUpdateRefreshTime: number;
  showUserMaintenance:boolean=false;

  constructor(private authenticationService: AuthenticationService) {

  }
  ngOnInit() {
    this.authenticationService.getAppVersion().subscribe(
      data => {
        this.appVersion = data;
      },
      error => {
      });
    this.authenticationService.getVersionUpdateRefreshTime().subscribe(
      data => {
        if (data && data > 0) {
          this.VersionUpdateRefreshTime = data * 60 * 100;
          var c = this;
          setInterval(function () {
            c.authenticationService.getAppVersion().subscribe(
              data => {
                if (data != c.appVersion) {
                  window.location.reload();
                }
              },
              error => {
              });
          }, this.VersionUpdateRefreshTime);
        }
      },
      error => {
      });
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.currentUser[0].roleId === UserPriviledge.Administrator) {
        this.showUserMaintenance=true;
      }else{
        this.showUserMaintenance=false; //this was false
      }
  }

  @Input()
  get filter() {
    return this.filterString;
  }

  set filter(val) {
    this.filterString = val;
    this.filterChange.emit(this.filterString);
  }



}
