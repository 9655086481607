export class WorkPayrollModal{
    PayrollEntryID :number;
    employee_uid :string;
    payrate :string;
    employee_types_uid :string;
    ImportHeaderId :string;

    ShiftDate:Date;
    ShiftMinutes:number;
    REGMinutes:number;
    OTMinutes:number;
    DTMinutes:number;

    IsSeventhDayAdjustment:boolean;
    IsOver6HourDay:boolean;
    IsOver12HourDay:boolean;
    payRoleStatusID:number;
    StatusComment:string;
    bachnumb:string;

    }
    
  