import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/index';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { RegisterComponent } from './register/index';
import { MaintenanceShellComponent } from './maintenance-shell/maintenance-shell.component';
import { UserMaintenanceComponent } from './usermaintenance/index';
import { PayRollShellComponent } from './payroll-shell/payroll-shell.component';
import { PayrollreportComponent } from './report/payroll/payrollreport.component';
import { ReportShellComponent } from './report-shell/report-shell.component';
import { StoreMaintenanceComponent } from './storemaintenance/index';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgetpassword',
    component: ForgetpasswordComponent
  },
  {
    path: 'changepassword',
    component: ResetpasswordComponent
  },
  
  {
    path: 'about/:id',
    component: AboutComponent
  },
  // { path: 'register', component: RegisterComponent },
  {
    path: 'maintenance', component: MaintenanceShellComponent, children: [
      { path: 'usermaintenance', component: UserMaintenanceComponent }
    ]
  },
  {
    path: 'storemaintenance', component: MaintenanceShellComponent, children: [
      { path: 'storemaintenance', component: StoreMaintenanceComponent }
    ]
  },
  {
    path: 'payroll', component: PayRollShellComponent, children: [
      { path: 'timesheet', component: HomeComponent } 
     
    ]
  },
  {
    path: 'report', component: ReportShellComponent, children: [
      { path: 'payroll', component: PayrollreportComponent } 
     
    ]
  }
 
];

export const AppRoutingModule = RouterModule.forRoot(routes, { useHash: true });
