import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { User } from '../datamodal/index';
import { AuthenticationService, SidebarLocatorService } from '../service/index';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-maintenance-shell',
  templateUrl: './maintenance-shell.component.html',
  styleUrls: ['./maintenance-shell.component.scss']
})
export class MaintenanceShellComponent implements OnInit {
  @ViewChild('sidebar') sidebar: SidebarComponent;

  public currentUser: User;
  public logoData = '';


  constructor(private router: Router, private authenicationServie: AuthenticationService, private spinner: NgxSpinnerService,
    private sidebarService: SidebarLocatorService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // this.authenicationServie.getCurrentCompanyLogo().subscribe(response => {
    //   this.logoData = 'data:image/jpg;base64,' + response.json();
    // });

  }

  ngOnInit() {
    // this.sidebarService.setSidebar(this.sidebar);
    
    // if(this.currentUser[0].roleId === 4){
    //   const menuItems = [['Payroll', '/maintenance/payroll'],
    //   ['User Maintenance', '/maintenance/usermaintenance']
    //   ];

    //   this.sidebar.ParseList(menuItems, x => x[0], x => x[1]);
    //   this.sidebar.LinkClicked(menuItems[0][1]);

    // }else{

    //   const menuItems = [['Payroll', '/maintenance/payroll']
      
    //   ];

    //   this.sidebar.ParseList(menuItems, x => x[0], x => x[1]);
    //   this.sidebar.LinkClicked(menuItems[0][1]);
    // }
   

   
  }

   SwitchSubpage(path: string) {
     //this.itemSidebar.Clear();
     this.router.navigate([path]);
   }

  filterItem() { }

  ItemSelected(obj) {

  }
}