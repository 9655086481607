import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService } from '../../service/index'
import { User, Store, UserRole, Work_clocksetsModal, PollEmployeeWork ,detailsPollEmployeeWork} from '../../datamodal/index';
import { ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
@Component({
    selector: 'employeedetail.dialog',
    templateUrl: 'employeedetail.dialog.html',
    styleUrls: ['employeedetail.dialog.scss'],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})
export class EmployeeDetailDialog {
    passwordMatch: boolean;
    userModel: PollEmployeeWork[];
    // signUpForm: FormGroup;

    form: FormGroup;

    roleList: UserRole[] = [];
    message: string;
    currentUser: User;
    saveToModel: User;
    // displayedColumns = [ 'shiftDate', 'clockIn', 'clockOut', 'shiftMinutes', 'break'];
    displayedColumns = [ 'storeId','shiftDate','clockIn', 'clockOut', 'shiftMinutes', 'break'];


    dataSource: MatTableDataSource<detailsPollEmployeeWork>;
    importHeaderId: string;
    employeeName: string;
    shiftDate: string;
    exceptionType: string;
    isMealWaiverOnFile:boolean;

    saveMessage: string;
    messageType: string;
    userfileData: User[] = [];

    filtereduserList: UserRole[] = [];
    expandedElement:any;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('paginator') paginator: MatPaginator;


    // @ViewChild('matSort') sort: MatSort;


    constructor(private userService: UserService,
        private alertService: AlertService,
        public dialogRef: MatDialogRef<EmployeeDetailDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.saveToModel = data.user;
        this.userModel = data.user;
        this.importHeaderId = data.importHeaderId;
        this.employeeName = data.employeeName;
        this.shiftDate = data.shiftDate;
        this.exceptionType = data.exceptionType;
        this.isMealWaiverOnFile=data.isMealWaiverOnFile;
       
    }

    // private display(a){
    //     var hours = Math.trunc(a/60);
    //     var minutes = a % 60;
    //     return hours +"Hour:"+ minutes +"Min" ;
    //   }
ngOnInit()
{
    this.userModel.forEach(element => {
        if (element.importHeaderId === this.importHeaderId) {
            element.isselectedImportHeaderId = true; //Show yellow color
        }
        else if (element.isStoreEntryOverlap === true) {
            element.isStoreEntryOverlap = true; //Show Red color
        }
        
        // if(element.exceptionType.trim().toLowerCase()=="clean"&&!element.isMPVWaiverFound)
        // {
        //     element.columnClass=""; 
        // }else if(element.isMPVWaiverFound)
        // {
        //     element.columnClass="#efea58";
        // }
        // else if(element.payRoleStatusID==0)
        // {
        //     element.columnClass="#9ef442";
        // }
        // else if(element.payRoleStatusID==1)
        // {
        //     element.columnClass="#f47784";
        // }
      
    });

    var highlightedList = this.userModel.filter(x => x.importHeaderId === this.importHeaderId)
    var list = this.userModel.filter(x => x.importHeaderId !== this.importHeaderId)

    //pushing highlightedlist and list in new array for displaying yellow and white in grid
    var result = [];
    highlightedList.forEach(item => {
        result.push(item);
    });
    list.forEach(data => {
        result.push(data);
    });

    this.dataSource = new MatTableDataSource(result);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
    // ngAfterViewInit() {
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }

    onOkClick(): void {

        // if (this.validateRequired()) {
        //     if (this.userModel.roleId !== null) {
        //         this.passwordMatch = this.passwordsMatch(this.userModel.password, this.userModel.confirmPassword)
        //         if (this.passwordMatch === false) {
        //             this.message = " Password doesn't match.";
        //         } else {
        //             this.userService.updateRequestSiteAccess(this.userModel)
        //                 .subscribe(
        //                     data => {
        //                         this.alertService.success('Registration update successful', true);
        //                     },
        //                     error => {
        //                         this.alertService.error(error);
        //                     });

        //             Object.assign(this.saveToModel, this.userModel);
        //             this.message = "";
        //             this.dialogRef.close(this.data);
        //         }
        //     } else {
        //         this.message = " Please select Roles.";
        //     }
        // } else {
        //     this.message = "Please provide all the required info.";
        // }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }



}

