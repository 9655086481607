import { Component, OnInit, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { PollfileService } from '../service/pollfile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertService, UserService, SidebarLocatorService } from '../service/index';
import { SelectionModel } from '@angular/cdk/collections';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PollFileModel, PollNewFileBaseModel, PollNewFileModel, PollFileBaseModel, PollFileLookUpModel, PollFilePeriodModel, PollFileStoreModel, PollFileStoreModelWithUser } from '../datamodal/pollfileModal'
import { Jsonp } from '@angular/http';
import { User, WorkPayrollModal } from '../datamodal/index';
import { EmployeeDetailDialog } from '../dialogs/employeedetail/employeedetail.dialog';
import { PayrollRejectionDialog } from '../dialogs/payrollrejection/payrollrejection.dialog';
import { UploadOpenSubmitPopup } from '../dialogs/uploadopensubmitpopup/uploadopensubmitpopup.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { PayrollStatus, UserPriviledge } from '../enum/enum';
import { Observable } from 'rxjs/Rx';
import { SelectedPayPeriodModel } from '../datamodal/payperiodmodal';
import { ConfirmDialog } from '../dialogs/confirm.dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  recordStatus: number;
  generalOpenDisplayedColumns = ['employeeName', 'workDate', 'exceptionType', 'download', 'upload'];
  generalSubmittingDisplayedColumns = ['employeeName', 'workDate', 'exceptionType', 'download', 'delete'];

  // distrinctOpenDisplayedColumns = ['employeeName', 'workDate', 'hoursWorked', 'exceptionType', 'download', 'upload', 'select'];
  distrinctOpenDisplayedColumns = ['employeeName', 'workDate', 'exceptionType', 'download', 'upload'];
  distrinctWaitingApprovedDisplayedColumns = ['select','employeeName', 'workDate', 'exceptionType', 'download', 'delete'];
  distrinctApprovedDisplayedColumns = ['employeeName', 'workDate', 'exceptionType', 'download'];
  //distrinctSummaryDisplayedColumns = ['homeDepartmentCode', 'fileNumber', 'employeeName', 'hoursWorked', 'premiumPaidHours', 'regularHours', 'overTimeHours', 'doubleTimeHours', 'regularPayRateAmount', 'detail'];
  distrinctSummaryDisplayedColumns = ['storeId', 'fileNumber', 'employeeName', 'hoursWorked', 'premiumPaidHours', 'regularHours', 'overTimeHours', 'doubleTimeHours', 'detail'];

  // newDisplayedColumns = ['employeeName', 'payrate', 'hoursWorked', 'vac', 'vacBalance', 'vacAmt', 'sick', 'sickBalance', 'sickAmount', 'totalHours'];

  // payrollOpenDisplayedColumns = ['employeeName', 'workDate', 'hoursWorked', 'exceptionType', 'download', 'upload', 'action', 'action1'];
  payrollOpenDisplayedColumns = ['employeeName', 'workDate', 'exceptionType', 'download', 'upload'];
  // approvedDisplayedColumns = ['employeeName', 'workDate', 'hoursWorked', 'exceptionType','download','delete', 'action', 'action1'];
  PayrollWaitingapprovedDisplayedColumns = ['select','employeeName', 'workDate', 'exceptionType', 'download', 'delete'];
  PayrollapprovedDisplayedColumns = ['employeeName', 'workDate', 'exceptionType', 'download'];
  // rejecteddisplayedColumns = ['employeeName', 'workDate', 'hoursWorked', 'exceptionType', 'action', 'action1', 'notes'];
  // removeddisplayedColumns = ['employeeName', 'workDate', 'hoursWorked', 'exceptionType', 'action'];
  //payrollSummarydisplayedColumns = ['homeDepartmentCode', 'fileNumber', 'employeeName', 'hoursWorked', 'premiumPaidHours', 'regularHours', 'overTimeHours', 'doubleTimeHours', 'sickTime', 'sickBalance', 'regularPayRateAmount', 'detail'];
  payrollSummarydisplayedColumns = ['storeId', 'fileNumber', 'employeeName', 'hoursWorked', 'premiumPaidHours', 'regularHours', 'overTimeHours', 'doubleTimeHours', 'sickTime', 'sickBalance', 'detail'];
  generalOpenDataSource: MatTableDataSource<PollFileModel>;
  generalSubmittingDataSource: MatTableDataSource<PollFileModel>;
  distrinctOpenDataSource: MatTableDataSource<PollFileModel>;
  distrinctWaitingApprovedDataSource: MatTableDataSource<PollFileModel>;

  distrinctApprovedDataSource: MatTableDataSource<PollFileModel>;
  distrinctSummaryDataSource: MatTableDataSource<PollFileModel>;


  dataSource: MatTableDataSource<PollFileModel>;
  PayrollWaitingapprovedDataSource: MatTableDataSource<PollFileModel>;
  PayrollapprovedDataSource: MatTableDataSource<PollFileModel>;
  // rejectedDataSource: MatTableDataSource<PollFileModel>;
  // removedDataSource: MatTableDataSource<PollFileModel>;
  payrollSummaryDataSource: MatTableDataSource<PollFileModel>;
  // newDataSource: MatTableDataSource<PollNewFileModel>;

  waiver: boolean;
  exceedSickBalanceMessage: string;
  saveMessage: string;
  messageType: string;
  distrinctWaitingSelection = new SelectionModel<PollFileModel>(true, []);
  isSelectAllDistrictWaiting:boolean=false;
  payrollWaitingSelection = new SelectionModel<PollFileModel>(true, []);

  selection = new SelectionModel<PollFileModel>(true, []);

  distrinctSelection = new SelectionModel<PollFileModel>(true, []);
  distrinctSelection1 = new SelectionModel<PollFileModel>(true, []);

  showGeneralDetails: boolean = false;
  showDistrinctDetails: boolean = false;
  showPayrollDetails: boolean = false;

  generalOpenbaseModel: PollFileBaseModel;
  generalbaseModel: PollFileBaseModel;
  generalOpenfileData: PollFileModel[] = [];
  generalSubmittingbaseModel: PollFileBaseModel;
  generalSubmittingfileData: PollFileModel[] = [];
  distrinctOpenbaseModel: PollFileBaseModel;
  distrinctOpenfileData: PollFileModel[] = [];
  distrinctWaitingApprovedbaseModel: PollFileBaseModel;
  distrinctWaitingApprovedfileData: PollFileModel[] = [];
  distrinctApprovedbaseModel: PollFileBaseModel;
  distrinctApprovedfileData: PollFileModel[] = [];
  distrinctSummarybaseModel: PollFileBaseModel;
  distrinctSummaryfileData: PollFileModel[] = [];


  pollbaseModel: PollFileBaseModel;
  pollfileData: PollFileModel[] = [];
  PayrollWaitingapprovedPollbaseModel: PollFileBaseModel;
  PayrollWaitingapprovedPollfileData: PollFileModel[] = [];
  PayrollapprovedPollbaseModel: PollFileBaseModel;
  PayrollapprovedPollfileData: PollFileModel[] = [];
  // rejectedPollbaseModel: PollFileBaseModel;
  // rejectedPollfileData: PollFileModel[] = [];
  // removedPollbaseModel: PollFileBaseModel;
  // removedPollfileData: PollFileModel[] = [];
  payrollSummaryPollbaseModel: PollFileBaseModel;
  payrollSummaryPollfileData: PollFileModel[] = [];
  // newPollbaseModel: PollNewFileBaseModel;
  // newPollfileData: PollNewFileModel[] = [];

  storeList: PollFileStoreModel[] = [];
  storeListWithUser: PollFileStoreModelWithUser[] = [];
  periodList: PollFilePeriodModel[] = [];
  pollfileLookUp: PollFileLookUpModel;
  public modalRef: BsModalRef; // {1}
  list: any;
  private totalStoreId: string[][];
  isSelectAllApproved: boolean = false;
  payRoleLatestStatus: string;
  reportBaseURL: string;
  serverBaseURL: string;
  reportURLExpression: string;
  ReportAutoLogin: boolean;
  messageHeaderClass: string = "ks-header";
  // adpExportURL:string;
  // mealWaiverURL:string;
  // mealWaiverSummaryURL:string;
  // managerUploadingDocumentURL:string;
  @Output() loaderEvent = new EventEmitter<string>();



  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('paginator2') paginator2: MatPaginator;
  @ViewChild('paginator3') paginator3: MatPaginator;
  @ViewChild('paginator4') paginator4: MatPaginator;
  @ViewChild('paginator5') paginator5: MatPaginator;
  @ViewChild('paginator6') paginator6: MatPaginator;
  @ViewChild('paginator7') paginator7: MatPaginator;
  @ViewChild('paginator8') paginator8: MatPaginator;
  @ViewChild('paginator9') paginator9: MatPaginator;
  @ViewChild('paginator10') paginator10: MatPaginator;
  @ViewChild('paginator11') paginator11: MatPaginator;
  @ViewChild('paginator12') paginator12: MatPaginator;

  @ViewChild('sort') sort: MatSort;
  @ViewChild('sort2') sort2: MatSort;
  @ViewChild('sort3') sort3: MatSort;
  @ViewChild('sort4') sort4: MatSort;
  @ViewChild('sort5') sort5: MatSort;
  @ViewChild('sort6') sort6: MatSort;
  @ViewChild('sort7') sort7: MatSort;
  @ViewChild('sort8') sort8: MatSort;
  @ViewChild('sort9') sort9: MatSort;
  @ViewChild('sort10') sort10: MatSort;
  @ViewChild('sort11') sort11: MatSort;
  @ViewChild('sort12') sort12: MatSort;



  dsData: any;
  public currentUser: User;
  selectedUserList: User[] = [];
  showAdminStore: boolean = false;
  showUserStore: boolean = false;
  userDetail: any;


  showOpenTab: boolean = true;
  showApprovedTab: boolean = true;
  showRejectedTab: boolean = true;
  showRemovedTab: boolean = true;
  showNewTab: boolean = true;


  showGeneralOpenTab: boolean = true;
  showGeneralSubmittingTab: boolean = true;
  showDistrinctOpenTab: boolean = true;
  showDistrinctWaitingApprovedTab: boolean = true;
  showDistrinctApprovedTab: boolean = true;
  showDistrinctSummaryTab: boolean = true;


  storeId: string;
  showPayPeriod: boolean = true;
  selectedStoreId: string;
  selectedPeriodId: number;
  tabTitle: string;


  isOpenTabActive: boolean = true;
  isPayrollWaitingApprovedTabActive: boolean = false;
  isPayrollApprovedTabActive: boolean = false;
  isRejectedTabActive: boolean = false;
  isRemovedTabActive: boolean = false;
  isPayRollSummaryTabActive: boolean = false;
  isNewTabActive: boolean = false;

  isGeneralOpenTabActive: boolean = true;
  isGeneralSubmittingTabActive: boolean = false;
  isDistrictOpenTabActive: boolean = true;
  isDistrictWaitingApprovedTabActive: boolean = false;
  isDistrictApprovedTabActive: boolean = false;
  isDistrictSummaryTabActive: boolean = false;


  column: string = 'payrollEntryID';

  constructor(private _data: PollfileService, private alertService: AlertService, public dialog: MatDialog, private userService: UserService, private modalService: BsModalService, private sidebarService: SidebarLocatorService, private spinner: NgxSpinnerService, private selectedPayPeriodModel: SelectedPayPeriodModel) 
  {


  }


  ngOnInit() {

    // this._data.getReportBaseURL().subscribe(
    //   data => {
    //     this.reportBaseURL = data;
    //   },
    //   error => {
    //   });
    this._data.getReportURLExpression().subscribe(
      data => {
        this.reportURLExpression = data;
      },
      error => {
      });
    this._data.getReportAutoLogin().subscribe(
      data => {
        this.ReportAutoLogin = data;
      },
      error => {
      });

    this._data.getServerBaseURL().subscribe(
      data => {
        this.serverBaseURL = data;
      },
      error => {
      });
    this.tabTitle = "Open";

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    //if user is not logged in redirect to login page
    if(this.currentUser==null)
    {
      window.location.href = '';
    }

    this.selectedUserList = JSON.parse(localStorage.getItem('currentUser'));
    this.userDetail = JSON.parse(localStorage.getItem('currentUser'));
    // this.selectedPeriodId = this.selectedPayPeriodModel.periodId;
    this.showAdminStore = true;
    this.selectedStoreId = null;
    if (this.currentUser[0].roleId === UserPriviledge.Administrator) {
      //these were in originally
       this.messageHeaderClass = "ks-header";
      // this.showUserStore = false;
      // this.showPayrollDetails = true;
      // this.loadStores();

      this.spinner.show();
      this.showAdminStore = false;
      this.showUserStore = true;

      var storeId = this.currentUser[0].storeId;
      this.selectedStoreId = storeId;
      this.userPriviledgeForTab();
      // this.loadStoreByPeriodIdAndUser();

      this.getPollFilePayPeriods(storeId, 0);

    } else {
      this.messageHeaderClass = "ks-header message-header";
      this.spinner.show();
      this.showAdminStore = false;
      this.showUserStore = true;

      var storeId = this.currentUser[0].storeId;
      this.selectedStoreId = storeId;
      this.userPriviledgeForTab();
      // this.loadStoreByPeriodIdAndUser();

      this.getPollFilePayPeriods(storeId, 0);
    }
  }

  showDownload(row: PollFileModel) {
    let reportURL: string;
    if (row.isMealWaiverOnFile) {
      //reportURL = this.reportBaseURL + "MealWaiver?Format=PDF&pSSN=" + row.ssn + "&pEmployee=" + row.employeeID + "&pDate=" + row.workDate;
      reportURL = this.reportURLExpression.replace("{{ReportName}}", "MealWaiver") + "Format=PDF&pSSN=" + row.ssn + "&pEmployee=" + row.employeeID + "&pDate=" + row.workDate;
    }
    window.open(reportURL, '_blank');
  }
  showDownloadSummary(row: PollFileModel) {
    let reportURL: string;
    if (row.isMealWaiverOnFile) {
      //reportURL = this.reportBaseURL + "MealWaiverSummary?Format=PDF&pSSN=" + row.ssn + "&pEmployee=" + row.employeeID + "&pPeriodID=" + this.selectedPeriodId;
      reportURL = this.reportURLExpression.replace("{{ReportName}}", "MealWaiverSummary") + "Format=PDF&pSSN=" + row.ssn + "&pEmployee=" + row.employeeID + "&pDate=" + row.workDate;
    }
    window.open(reportURL, '_blank');
  }
  downloadGeneralOpen(row: PollFileModel) {

    let reportURL: string;
    if (this.ReportAutoLogin) {
      reportURL = this.serverBaseURL + "api/poll/downloadSSRSReport?ReportName=ManagerApprovalReport&ReportFormat=pdf&ReportDownloadName=ManagerApprovalReport.pdf&Parameters=Format=PDF~pClocksetsId=" + row.clocksetsId + "~pRowId=" + row.rowID +"~pExceptionType=" + row.exceptionType + "~pStoreId=" + row.storeId + "";
    } else {
      reportURL = this.reportURLExpression.replace("{{ReportName}}", "ManagerApprovalReport") + "Format=PDF&pClocksetsId=" + row.clocksetsId + "&pRowId=" + row.rowID +"&pExceptionType=" + row.exceptionType + "&pStoreId=" + row.storeId + "";
    }
    window.open(reportURL, '_blank');
  }
  downloadGeneralSubmitted(row: PollFileModel) {
    if (!row.isMPVWaiverFound) {
      let reportURL: string;
      if (row.uploadedFileName) {
        reportURL = this.serverBaseURL + "api/poll/downloadUploadedFile?fileName=" + row.uploadedFileName;
        window.open(reportURL, '_blank');
      }
    }

  }
  deleteGeneralSubmitted(row: PollFileModel) {
    if (row.uploadedFileName) {
      let confirmDialogRef = this.dialog.open(ConfirmDialog, {
        width: '400px',
        data: { title: 'Are you sure want to remove the uploaded file..? ' }
      });

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          let parameter: PollFileModel[];
          parameter = [row];
          parameter.forEach(element => {
            element.payRoleStatusID = PayrollStatus.Open;
            element.uploadedFileName = null;
          });
          this.spinner.show();
          this._data.updateGeneralEntry(parameter, this.selectedStoreId, this.selectedPeriodId).subscribe(response => {
            if (response) {

              //this.getAllGeneralTabData(this.selectedStoreId, this.selectedPeriodId)
              //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
              this.saveMessage = "Sent record back to open status";
              this.messageType = "success";
            }
            error => {
              this.saveMessage = "Not saved.";
              this.messageType = "error";
              this.spinner.hide();
            }
          });

              //this._data.updatePayRollEntry(parameter[0], this.selectedStoreId, this.selectedPeriodId)

              if(row.exceptionType.toLowerCase().indexOf("meal")>0)
              {
                row.exceptionTypeID=1;
              }
              else
              {
                row.exceptionTypeID=2;
              }


              this._data.updateWorkRecordStatus(row.clocksetsId,row.rowID,row.storeId,1, row.exceptionTypeID)
              .subscribe(
                data => {
                  //Remove row manually
                  //this.removeRowData(parameter[0], 'approved');
                  //this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId);
                  //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);


                  this.filterAdminChanged();

                  //Add row manually
                  // this.addRemovedRowData(event);
                  //check paying period want to show or not
                  this.showPayPeriodControl();
  
                  this.saveMessage = "Sent record back to open status.";
                  this.messageType = "success";
                },
                error => {
                  this.saveMessage = "Not saved.";
                  this.messageType = "error";
                  this.spinner.hide();
                });  

        }
      });
    }
  }
  uploadGeneralDistrictPayrollOpen(row: PollFileModel) {
    let userDialogRef = this.dialog.open(UploadOpenSubmitPopup, {

      width: "400px",
      height: "400px",
      data: {
        title: 'Upload File',
        rowData: row
      },
      hasBackdrop: false,
    });
    const sub = userDialogRef.componentInstance.onClkUpload.subscribe((par: { rowData: PollFileModel, file: File }) => {
      if (this.isGeneralOpenTabActive && this.showGeneralDetails) {
        let parameter: PollFileModel[];
        parameter = [par.rowData];

        //this.uploadGeneralOpenEntry(parameter, "Submitting", par.file);
        this.uploadPayrollOpenEntry(par.rowData, "open", par.file)
      }
      else if (this.isDistrictOpenTabActive == true && this.showDistrinctDetails) {
        let parameter: PollFileModel[];
        parameter = [par.rowData];
        //this.uploadDistrictOpenEntry(parameter, "WaitingApproval", par.file);
        this.uploadPayrollOpenEntry(par.rowData, "open", par.file)
      }
      else if (this.showPayrollDetails) {
        this.uploadPayrollOpenEntry(par.rowData, "open", par.file)
      }
      userDialogRef.close();
    });



    this.spinner.hide();
    userDialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {

      }
    });
  }
  downloadDistrictOpen(row: PollFileModel) {
    let reportURL: string;
    if (this.ReportAutoLogin) {
      reportURL = this.serverBaseURL + "api/poll/downloadSSRSReport?ReportName=ManagerApprovalReport&ReportFormat=pdf&ReportDownloadName=ManagerApprovalReport.pdf&Parameters=Format=PDF~pClocksetsId=" + row.clocksetsId + "~pRowId=" + row.rowID +"~pExceptionType=" + row.exceptionType + "~pStoreId=" + row.storeId + "";
    } else {
      reportURL = this.reportURLExpression.replace("{{ReportName}}", "ManagerApprovalReport") + "Format=PDF&pClocksetsId=" + row.clocksetsId + "&pRowId=" + row.rowID +"&pExceptionType=" + row.exceptionType + "&pStoreId=" + row.storeId + "";
    }
    window.open(reportURL, '_blank');
  }

  downloadDistrictSubmitted(row: PollFileModel) {
      let reportURL: string;
      if (row.uploadedFileName) {
        reportURL = this.serverBaseURL + "api/poll/downloadUploadedFile?fileName=" + row.uploadedFileName;
        window.open(reportURL, '_blank');
    }
  }
  deleteDistrictSubmitted(row: PollFileModel) {
    if (row.uploadedFileName) {
      let confirmDialogRef = this.dialog.open(ConfirmDialog, {
        width: '400px',
        data: { title: 'Are you sure want to remove the uploaded file..? ' }
      });

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          let parameter: PollFileModel[];
          parameter = [row];
          parameter.forEach(element => {
            element.payRoleStatusID = PayrollStatus.Open;
            element.uploadedFileName = null;
          });
          this.spinner.show();
          this._data.updateGeneralAndDistrinctEntry(parameter, this.selectedStoreId, this.selectedPeriodId).subscribe(response => {
            if (response) {
              //this.getAllDistrictTabData(this.selectedStoreId, this.selectedPeriodId)
              //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
              this.saveMessage = "Sent record back to open status";
              this.messageType = "success";
            }
            error => {
              this.saveMessage = "Not saved.";
              this.messageType = "error";
              this.spinner.hide();
            }
          });

          if(row.exceptionType.toLowerCase().indexOf("meal")>0)
          {
            row.exceptionTypeID=1;
          }
          else
          {
            row.exceptionTypeID=2;
          }


              //this._data.updatePayRollEntry(parameter[0], this.selectedStoreId, this.selectedPeriodId)
              this._data.updateWorkRecordStatus(row.clocksetsId,row.rowID,row.storeId,1, row.exceptionTypeID)
              .subscribe(
                data => {
                  //Remove row manually
                  //this.removeRowData(parameter[0], 'approved');
                  //this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId);
                  //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);

                  this.filterAdminChanged();

                  //Add row manually
                  // this.addRemovedRowData(event);
                  //check paying period want to show or not
                  this.showPayPeriodControl();
  
                  this.saveMessage = "Sent record back to open status.";
                  this.messageType = "success";
                },
                error => {
                  this.saveMessage = "Not saved.";
                  this.messageType = "error";
                  this.spinner.hide();
                });  

        }
      });
    }
  }
  downloadPayrollOpen(row: PollFileModel) {
    let reportURL: string;
    if (this.ReportAutoLogin) {
      reportURL = this.serverBaseURL + "api/poll/downloadSSRSReport?ReportName=ManagerApprovalReport&ReportFormat=pdf&ReportDownloadName=ManagerApprovalReport.pdf&Parameters=Format=PDF~pClocksetsId=" + row.clocksetsId + "~pRowId=" + row.rowID +"~pExceptionType=" + row.exceptionType + "~pStoreId=" + row.storeId + "";
    } else {
      reportURL = this.reportURLExpression.replace("{{ReportName}}", "ManagerApprovalReport") + "Format=PDF&pClocksetsId=" + row.clocksetsId + "&pRowId=" + row.rowID +"&pExceptionType=" + row.exceptionType + "&pStoreId=" + row.storeId + "";
    }
    window.open(reportURL, '_blank');
  }
  downloadPayrollSubmitted(row: PollFileModel) {
      let reportURL: string;
      if (row.uploadedFileName) {
        reportURL = this.serverBaseURL + "api/poll/downloadUploadedFile?fileName=" + row.uploadedFileName;
        window.open(reportURL, '_blank');
      }
  }
  deletePayrollSubmitted(row: PollFileModel) {
    if (row.uploadedFileName) {
      let confirmDialogRef = this.dialog.open(ConfirmDialog, {
        width: '400px',
        data: { title: 'Are you sure want to remove the uploaded file..? ' }
      });

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          let parameter: PollFileModel[];
          parameter = [row];
          parameter.forEach(element => {
            element.payRoleStatusID = PayrollStatus.Open;
            element.uploadedFileName = null;
          });
          //removed 5/07/2019
          this._data.updatePayRollEntry(parameter[0], this.selectedStoreId, this.selectedPeriodId)
           .subscribe(
             data => {
               //Remove row manually
               //this.removeRowData(parameter[0], 'approved');

               //this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId);
               //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);

               //Add row manually
               // this.addRemovedRowData(event);
               //check paying period want to show or not
               this.showPayPeriodControl();

               this.saveMessage = "Sent record back to open status.";
               this.messageType = "success";
             },
             error => {
               this.saveMessage = "Not saved.";
               this.messageType = "error";
               this.spinner.hide();
             });


             if(row.exceptionType.toLowerCase().indexOf("meal")>0)
             {
               row.exceptionTypeID=1;
             }
             else
             {
               row.exceptionTypeID=2;
             }

              //this._data.updatePayRollEntry(parameter[0], this.selectedStoreId, this.selectedPeriodId)
              this._data.updateWorkRecordStatus(row.clocksetsId,row.rowID,row.storeId,1, row.exceptionTypeID)
              .subscribe(
                data => {
                  //Remove row manually
                  //this.removeRowData(parameter[0], 'approved');
                  //this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId);
                  //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);

                  this.filterAdminChanged();

                  //Add row manually
                  // this.addRemovedRowData(event);
                  //check paying period want to show or not
                  this.showPayPeriodControl();
  
                  this.saveMessage = "Sent record back to open status.";
                  this.messageType = "success";
                },
                error => {
                  this.saveMessage = "Not saved.";
                  this.messageType = "error";
                  this.spinner.hide();
                });              
        }
      });
    }
  }


  ngAfterViewInit() {
  }

  // submitData() {
  //   if (this.isGeneralOpenTabActive && this.showGeneralDetails) {
  //     this.clickSubmitting(this.generalOpenDataSource.data, "Submitting")
  //   }
  //   else if (this.isDistrictOpenTabActive == true && this.showDistrinctDetails) {
  //     this.clickWaitingApproval(this.distrinctOpenDataSource.data, "WaitingApproval")
  //   }
  //   else if (this.showPayrollDetails) {
  //     this.importToGP()
  //   }
  // }

  // importToGP() {
  //   this.spinner.show();
  //   this._data.saveworkGPBatchHeader(this.approvedPollfileData, this.currentUser, this.selectedStoreId, this.selectedPeriodId).subscribe(response => {
  //     if (response) {
  //       this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId)
  //       this.saveMessage = "Import QSRGP successfully .";
  //       this.messageType = "success";
  //       this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
  //     }
  //     else {
  //       this.saveMessage = "Error.";
  //       this.messageType = "error";
  //       this.spinner.hide();
  //     }
  //   });

  // }

  removeRowData(event: PollFileModel, status: string) {
    if (status === "open") {
      this.removeOpenRowData(event);
    }
    else if (status === "approved") {
      this.removeApprovedRowData(event);
    } else if (status === "rejected") {
      // this.removeRejectedRowData(event);
    } else if (status === "removed") {
      // this.removeRemovedRowData(event);
    }
  }

  removeOpenRowData(event: PollFileModel) {
    this.dsData = this.dataSource.data;
    const itemIndex = this.dsData.findIndex(obj => obj.payrollEntryID === event.payrollEntryID);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  removeApprovedRowData(event: PollFileModel) {
    this.dsData = this.PayrollWaitingapprovedDataSource.data;
    const itemIndex = this.dsData.findIndex(obj => obj.payrollEntryID === event.payrollEntryID);
    this.PayrollWaitingapprovedDataSource.data.splice(itemIndex, 1);
    this.payrollWaitingSelection.clear();
    this.isSelectAllDistrictWaiting=false;
    this.PayrollWaitingapprovedDataSource.paginator = this.paginator2;
    this.PayrollWaitingapprovedDataSource.sort = this.sort2;
    // this.masterTogglePayrollWaiting();
  }

  // removeRejectedRowData(event: PollFileModel) {
  //   this.dsData = this.rejectedDataSource.data;
  //   const itemIndex = this.dsData.findIndex(obj => obj.payrollEntryID === event.payrollEntryID);
  //   this.rejectedDataSource.data.splice(itemIndex, 1);
  //   this.rejectedDataSource.paginator = this.paginator3;
  //   this.rejectedDataSource.sort = this.sort3;
  // }

  // removeRemovedRowData(event: PollFileModel) {
  //   this.dsData = this.removedDataSource.data;
  //   const itemIndex = this.dsData.findIndex(obj => obj.payrollEntryID === event.payrollEntryID);
  //   this.removedDataSource.data.splice(itemIndex, 1);
  //   this.removedDataSource.paginator = this.paginator4;
  //   this.removedDataSource.sort = this.sort4;
  // }

  // addRejectedRowData(event: PollFileModel) {
  //   const result = this.rejectedDataSource.data;
  //   result.push(event);
  //   this.rejectedDataSource.data = result;

  //   this.rejectedDataSource.data = this.rejectedDataSource.data.sort((item1, item2) => {
  //     if (item1.payrollEntryID > item2.payrollEntryID) {
  //       return 1;
  //     }
  //     if (item1.payrollEntryID < item2.payrollEntryID) {
  //       return -1;
  //     }
  //     return 0;
  //   });

  //   this.rejectedDataSource.paginator = this.paginator3;
  //   this.rejectedDataSource.sort = this.sort3;
  // }

  // addRemovedRowData(event: PollFileModel) {

  //   const result = this.removedDataSource.data;
  //   result.push(event);
  //   this.removedDataSource.data = result;

  //   this.removedDataSource.data = this.removedDataSource.data.sort((item1, item2) => {
  //     if (item1.payrollEntryID > item2.payrollEntryID) {
  //       return 1;
  //     }
  //     if (item1.payrollEntryID < item2.payrollEntryID) {
  //       return -1;
  //     }
  //     return 0;
  //   });
  //   this.removedDataSource.paginator = this.paginator4;
  //   this.removedDataSource.sort = this.sort4;
  // }
  clickSubmitting(event: PollFileModel[], status: string, filename?: string) {
    this.spinner.show();
    event.forEach(element => {
      element.payRoleStatusID = PayrollStatus.Approved;
      element.uploadedFileName = filename ? filename : element.uploadedFileName;
    });
    this.saveMessage = "";
    this._data.updateGeneralEntry(event, this.selectedStoreId, this.selectedPeriodId)
      .subscribe(
        data => {
          this.getAllGeneralTabData(this.selectedStoreId, this.selectedPeriodId)
          this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
          this.saveMessage = "The Record Submitted for Approval.";
          this.messageType = "success";
        },
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        });
  }



  uploadGeneralOpenEntry(event: PollFileModel[], status: string, file: File) {
    this.spinner.show();
    event.forEach(element => {
      element.payRoleStatusID = PayrollStatus.Approved;
    });
    this.saveMessage = "";
    const formData = new FormData();

    if(event[0].exceptionType.indexOf("meal")>0)
      event[0].exceptionTypeID=1;
    else
    event[0].exceptionTypeID=2;

    formData.append("General_Open_" + event[0].ssn + "_" + new Date(event[0].workDate).toDateString() + "_" + event[0].storeId + "_" + file.name, file);

    this._data.uploadFileOpenEntry(formData)
      .subscribe(
        data => {
          if (data == true) {
            this.clickSubmitting(event, status, "General_Open_" + event[0].ssn + "_" + new Date(event[0].workDate).toDateString() + "_" + event[0].storeId + "_" + file.name);
          } else {
            this.saveMessage = "Upload failed.";
            this.messageType = "error";
          }
        },
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        });

        
  }
  uploadDistrictOpenEntry(event: PollFileModel[], status: string, file: File) {
    this.spinner.show();
    event.forEach(element => {
      element.payRoleStatusID = PayrollStatus.Approved;
      element.checkedApproved = true;
    });
    this.saveMessage = "";
    const formData = new FormData();

    if(event[0].exceptionType.indexOf("meal")>0)
    event[0].exceptionTypeID=1;
  else
  event[0].exceptionTypeID=2;

    formData.append("District_Open_" + event[0].ssn + "_" + new Date(event[0].workDate).toDateString() + "_" + event[0].storeId + "_" + file.name, file);

    this._data.uploadFileOpenEntry(formData)
      .subscribe(
        data => {
          if (data == true) {
            this.clickWaitingApproval(event, status, "District_Open_" + event[0].ssn + "_" + new Date(event[0].workDate).toDateString() + "_" + event[0].storeId + "_" + file.name);
          } else {
            this.saveMessage = "Upload failed.";
            this.messageType = "error";
          }
        },
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        });
  }
  uploadPayrollOpenEntry(event: PollFileModel, status: string, file: File) {
    this.spinner.show();
    event.payRoleStatusID = PayrollStatus.Approved;
    this.saveMessage = "";
    const formData = new FormData();

    if(event.exceptionType.toLowerCase().indexOf("meal")>0)
    {
      event.exceptionTypeID=1;
    }
    else
    {
      event.exceptionTypeID=2;
    }

    formData.append("Payroll_Open_" + event.ssn + "_" + new Date(event.workDate).toDateString() + "_" + event.storeId + "_" + file.name, file);
    formData.append("ClocksetsId",event.clocksetsId);
    formData.append("RowID",event.rowID.toString());
    formData.append("StoreID",event.storeId.toString());
    formData.append("ExceptionTypeID",event.exceptionTypeID.toString());
    
    this._data.uploadFileOpenEntry(formData)
      .subscribe(
        data => {
          if (data == true) {
            this.clickApproved(event, status, "Payroll_Open_" + event.ssn + "_" + new Date(event.workDate).toDateString() + "_" + event.storeId + "_" + file.name);

            this.filterAdminChanged();

          } else {
            this.saveMessage = "Upload failed.";
            this.messageType = "error";
          }
        },
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        });

        // this.recordStatus=2;
        // this._data.updateWorkRecordStatus(event.clocksetsId,event.rowID, 2)
        // .subscribe(
        //   data => {
        //     if (data == true) {
        //       this.clickApproved(event, status, "Payroll_Open_" + event.ssn + "_" + new Date(event.workDate).toDateString() + "_" + event.storeId + "_" + file.name);
              
        //       //this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId);
        //       this.filterAdminChanged();

        //       this.saveMessage = "Record Submitted.";
        //       this.messageType = "success";

        //     } else {
        //       this.saveMessage = "Update Status failed.";
        //       this.messageType = "error";
        //     }
        //   },
        //   error => {
        //     this.saveMessage = "Not saved.";
        //     this.messageType = "error";
        //     this.spinner.hide();
        //   }); 

         
  }

  clickWaitingApproval(event: PollFileModel[], status: string, filename?: string) {
    this.spinner.show();
    this.recordStatus = 3;
    event = event.filter(x => x.checkedApproved == true || x.checkedRejected == true)
    event.forEach(element => {
      if (element.checkedApproved) {
        element.payRoleStatusID = PayrollStatus.Approved;
        element.uploadedFileName = filename ? filename : element.uploadedFileName;
      } else if (element.checkedRejected) {
        this.recordStatus = 5;
        element.payRoleStatusID = PayrollStatus.Open;
        element.uploadedFileName = null;
      }
    });
    this.saveMessage = "";
    this._data.updateGeneralAndDistrinctEntry(event, this.selectedStoreId, this.selectedPeriodId)
      .subscribe(
        data => {
          this.getAllDistrictTabData(this.selectedStoreId, this.selectedPeriodId);
          this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
          this.saveMessage = "The Record Submitted for Approval.";
          this.messageType = "success";
        },
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        });

        
  }

  clickApproved(event: PollFileModel, status: string, filename?: string) {
    //removed 5/07/2019
    this.spinner.show();
    event.payRoleStatusID = PayrollStatus.Approved;
    event.uploadedFileName = filename ? filename : event.uploadedFileName;
    this.saveMessage = "";
    this._data.updatePayRollEntry(event, this.selectedStoreId, this.selectedPeriodId)
     .subscribe(
       data => {

         //Remove row manually
         //this.removeRowData(event, status)
         //Add row manually
         //  this.addApprovedRowData(event);
         //this.getApprovedPollFileData(this.selectedStoreId, this.selectedPeriodId)
         this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId)
         this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
         //check paying period want to show or not
         this.showPayPeriodControl();

         this.saveMessage = "The Record Submitted for Approval.";
         this.messageType = "success";
       },
       error => {
         this.saveMessage = "Not saved.";
         this.messageType = "error";
         this.spinner.hide();
       });
  }

  clickRemoved(event: PollFileModel, status: string) {
    //removed 5/7/2019
    this.spinner.show();
    event.payRoleStatusID = PayrollStatus.Removed;;
    this.saveMessage = "";
    this._data.updatePayRollEntry(event, this.selectedStoreId, this.selectedPeriodId)
     .subscribe(
       data => {
         //Remove row manually
         this.removeRowData(event, status);
         //Add row manually
         // this.addRemovedRowData(event);
         //check paying period want to show or not
         this.showPayPeriodControl();

         this.saveMessage = "The Record Submitted for Approval.";
         this.messageType = "success";
       },
       error => {
         this.saveMessage = "Not saved.";
         this.messageType = "error";
         this.spinner.hide();
       });
  }

  clickReject(event: PollFileModel, isEdit: boolean, status: string) {
    if (event.payRoleStatusID == PayrollStatus.Rejected && isEdit) {
      this.saveMessage = "Store No is already rejected";
    } else {
      this.saveMessage = "";
      let userDialogRef = this.dialog.open(PayrollRejectionDialog, {
        width: '550px',
        height: '490px',
        data: {
          title: 'Note',
          user: event
        }
      });
      this.spinner.hide();
      userDialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          //removed 05/07/2019
          this.spinner.show();
          result.payRoleStatusID = PayrollStatus.Rejected;
          this.saveMessage = "";
          this._data.updatePayRollEntry(result, this.selectedStoreId, this.selectedPeriodId)
           .subscribe(
             data => {
               //Remove row manually
               this.removeRowData(event, status)
               //Add row manually
               // this.addRejectedRowData(event);

               //check paying period want to show or not
               this.showPayPeriodControl();
               this.saveMessage = "" + event.employeeName + " is Rejected.";
               this.messageType = "success";
               this.spinner.hide();
             },
             error => {
               this.saveMessage = "Not saved";
               this.messageType = "error";
               this.spinner.hide();
             });
        }
      });
    }
  }

  selectGeneralTab(tabName: string): void {
    this.tabTitle = tabName;
    this.isGeneralOpenTabActive = false;
    this.isGeneralSubmittingTabActive = false;
    if (tabName == "Open") {
      this.isGeneralOpenTabActive = true;
    } else if (tabName == "Submitting") {
      this.isGeneralSubmittingTabActive = true;
    }
  }


  selectDistrictTab(tabName: string): void {
    this.tabTitle = tabName;
    this.isDistrictOpenTabActive = false;
    this.isDistrictWaitingApprovedTabActive = false;
    this.isDistrictApprovedTabActive = false;
    this.isDistrictSummaryTabActive = false;
    if (tabName == "Open") {
      this.isDistrictOpenTabActive = true;
    } else if (tabName == "WaitingApproved") {
      this.isDistrictWaitingApprovedTabActive = true;
    }
    else if (tabName == "Approved") {
      this.isDistrictApprovedTabActive = true;
    } else if (tabName == "Summary") {
      this.isDistrictSummaryTabActive = true;
    }
  }

  selectTab(tabName: string): void {
    this.tabTitle = tabName;
    this.isOpenTabActive = false;
    this.isPayrollWaitingApprovedTabActive = false;
    this.isPayrollApprovedTabActive = false;
    this.isRejectedTabActive = false;
    this.isRemovedTabActive = false;
    this.isPayRollSummaryTabActive = false;
    this.isNewTabActive = false;

    if (tabName == "Open") {
      this.isOpenTabActive = true;
    } else if (tabName == "WaitingApproved") {
      this.isPayrollWaitingApprovedTabActive = true;
    }
    else if (tabName == "Approved") {
      this.isPayrollApprovedTabActive = true;
    } else if (tabName == "Rejected") {
      this.isRejectedTabActive = true;
    } else if (tabName == "Removed") {
      this.isRemovedTabActive = true;
    } else if (tabName == "New") {
      this.isNewTabActive = true;
    } else if (tabName == "Summary") {
      this.isPayRollSummaryTabActive = true;
    }
  }

  removeLastComma(str) {
    return str.replace(/,(\s+)?$/, '');
  }



  userPriviledgeForTab() {
    this.storeId = this.selectedStoreId;
    // let user = this.storeListWithUser.find(x => x.storeId == this.storeId.toString() && x.periodId == this.selectedPeriodId);
    let user = this.userDetail.find(x => x.storeId === this.storeId);
    this.showGeneralDetails = false;
    this.showDistrinctDetails = false;
    this.showPayrollDetails = false;
    if (UserPriviledge.GeneralManager === user.roleId) {
      this.showGeneralDetails = true;
    } else if (UserPriviledge.DistrictManager === user.roleId) {
      this.showDistrinctDetails = true;
    } else if (UserPriviledge.Administrator === user.roleId) {
      this.showPayrollDetails = true;
    } else if (UserPriviledge.PayrollManager === user.roleId) {
      this.showPayrollDetails = true;
    }
  }


  // payPeriodfilterChanged(){
  //     this.spinner.show();
  //     this.userPriviledgeForTab();
  //     this.getPollFilePayPeriods(this.selectedStoreId, this.selectedPeriodId);
  // }


  // loadStoresForUser(storeId: string, userName: string) {
  //   this.userService.getStoresForUser(storeId, userName).subscribe(
  //     data => {
  //       this.storeList = data;
  //       if (this.storeList.length > 0) {
  //         var storeId = parseInt(this.storeList[0].storeId);
  //         this.selectedStoreId = storeId;
  //         this.getPollFilePayPeriods(storeId, 0);
  //       }
  //     },
  //     error => {
  //       this.alertService.error(error);
  //     });
  // }

  showLoggedDetail(item: any) {
    this.loaderEvent.emit();
    var list;
    this._data.getEmployeeLoggedDetail(item.employeeID, item.importHeaderId, item.workDate, item.employeeID).subscribe(result => {

      const obj = result.json();
      list = obj;
      let userDialogRef = this.dialog.open(EmployeeDetailDialog, {
        width: '800px',
        height: '650px',
        data: {
          title: 'Employee Time Details',
          user: list,
          importHeaderId: item.importHeaderId,
          employeeName: item.employeeName,
          shiftDate: item.workDate,
          exceptionType: item.exceptionType,
          isMealWaiverOnFile:item.isMealWaiverOnFile
        }
      });
      this.spinner.hide();
      userDialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          let userModel = new User;
          // Update info to push in database.
          this.userService.updateRequestSiteAccess(item)
            .subscribe(
              data => {

              },
              error => {
                this.alertService.error(error);
              });
        }
      });
    });
  }
  showLoggedSummaryDetail(item: any) {
    this.loaderEvent.emit();
    var list;
    let Role: string;
    if (this.showDistrinctDetails) {
      Role = "District";
    } else if (this.showPayrollDetails) {
      Role = "Payroll";
    }
    this._data.getEmployeeLoggedSummaryDetail(item.homeDepartmentCode, item.employeeID, item.importHeaderId, item.ssn, this.selectedPeriodId, Role).subscribe(result => {

      const obj = result.json();
      list = obj;
      this.waiver = false;
      if(list!=null)
      {
        if(list.length>0)
        {
          this.waiver = list[0].isMealWaiverOnFile;
        }
      }
      let userDialogRef = this.dialog.open(EmployeeDetailDialog, {
        width: '800px',
        height: '650px',
        data: {
          title: 'Employee Time Details',
          user: list,
          importHeaderId: item.importHeaderId,
          employeeName: item.employeeName,
          // shiftDate: item.workDate,
          shiftDate: null,
          exceptionType: item.exceptionType,
          isMealWaiverOnFile:this.waiver
        }
      });
      this.spinner.hide();
      userDialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {
          let userModel = new User;
          // Update info to push in database.
          this.userService.updateRequestSiteAccess(item)
            .subscribe(
              data => {

              },
              error => {
                this.alertService.error(error);
              });
        }
      });
    });
  }

  loadStoreByPeriodIdAndUser() {
    if (this.selectedPayPeriodModel && this.selectedPeriodId) {
      this.selectedStoreId = null;
      this.storeListWithUser = [];
      this.spinner.show();
      this.userService.StoreByPeriodIdAndUser(this.selectedPeriodId, this.currentUser[0].userName).subscribe(
        data => {
          //  this.filteredItems = data;
          this.storeListWithUser = data;
          if (this.storeListWithUser.length > 0) {
            var storeId = this.storeListWithUser[0].storeId;
            this.selectedStoreId = storeId;
            this.userPriviledgeForTab();
            if (this.showAdminStore) {
              if (this.selectedStoreId) {
                this.filterAdminChanged();
              }
            } else if (this.showUserStore) {
              if (this.selectedStoreId) {
                this.filterChanged();
              }
            }
          }
          this.spinner.hide();
        },
        error => {
          this.alertService.error(error);
          this.spinner.hide();
        });
    } else {
      this.spinner.hide();
    }
  }

  loadStores() {
    this.selectedStoreId = null;
    this.storeList = [];
    this.spinner.show();
    this.userService.getStore().subscribe(
      data => {
        //  this.filteredItems = data;
        this.storeList = data;
        if (this.storeList.length > 0) {
          var storeId = this.storeList[0].storeId;
          this.selectedStoreId = storeId;
          if (this.showAdminStore) {
            if (this.selectedStoreId) {
              this.filterAdminChanged();
            }
          } else if (this.showUserStore) {
            if (this.selectedStoreId) {
              this.filterChanged();
            }
          }
        }
        this.spinner.hide();
      },
      error => {
        this.alertService.error(error);
        this.spinner.hide();
      });
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template); // {3}
  }

  private showPayPeriodControl() {
    // if ((this.dataSource.data.length <= 0) && (this.approvedDataSource.data.length <= 0)) {
    //   this.showPayPeriod = false;
    // } else {
    //   this.showPayPeriod = true;
    // }
  }

  onChange(item: any, result: any) {
    let index = this.distrinctOpenDataSource.data.indexOf(item);
    if (result) {
      this.distrinctOpenDataSource.data[index].checkedApproved = true;
      this.distrinctOpenDataSource.data[index].checkedRejected = false;
    } else {
      this.distrinctOpenDataSource.data[index].checkedApproved = false;
    }
  }

  onNextChange(item: any, result: any) {
    let index = this.distrinctOpenDataSource.data.indexOf(item);
    if (result) {
      this.distrinctOpenDataSource.data[index].checkedRejected = true;
      this.distrinctOpenDataSource.data[index].checkedApproved = false;
    } else {
      this.distrinctOpenDataSource.data[index].checkedRejected = false;
    }
  }

  filterChanged() {
    // this.spinner.show();
    this.userPriviledgeForTab();

    // this.selectedPeriodId = this.selectedPayPeriodModel.periodId;
    this.storeId = this.selectedStoreId;
    let res = this.storeListWithUser.filter(w => w.periodId == this.selectedPeriodId && w.storeId == this.selectedStoreId.toString());
    // this.payRoleLatestStatus = res[0].payRoleLatestStatus;
    if (this.selectedPeriodId > 0) {
      this.getPollFilePayPeriods(this.selectedStoreId, this.selectedPeriodId)
    }
  }




  filterAdminChanged() {
    // this.spinner.show();
    this.storeId = this.selectedStoreId;
    // this.selectedPeriodId = this.selectedPayPeriodModel.periodId;
    let res = this.storeList.filter(w => w.periodId == this.selectedPeriodId && w.storeId == this.selectedStoreId.toString());
    //this.payRoleLatestStatus = res[0].payRoleLatestStatus;
    this.getPollFilePayPeriods(this.selectedStoreId, this.selectedPeriodId)
  }

  private getPollFilePayPeriods(storeId: string, payPeriodId: number) {
    this.spinner.show();
    this._data.getPollFilePayPeriods(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      this.pollfileLookUp = obj;
      this.periodList = obj;
      if (this.periodList.length > 0) {

        if(payPeriodId==null)
        {
          payPeriodId = 0;
        }
        var payPeriodIdExists = this.periodList.filter(x => x.periodId == payPeriodId);

        if(payPeriodIdExists!=null && payPeriodId > 0)
        {
          this.selectedPeriodId=payPeriodId;
        }
        else
        {
          var CurrentPeriod = this.periodList.filter(x => x.selectedPeriod == 1);
          if (CurrentPeriod && CurrentPeriod.length > 0) 
          {
            this.selectedPeriodId = CurrentPeriod[0].periodId;
            this.payRoleLatestStatus = this.periodList[0].payRoleLatestStatus;
          } 
          else 
          {
            var periodId = this.periodList[0].periodId;
            if (this.selectedPeriodId === undefined || this.selectedPeriodId == null) 
            {
              this.payRoleLatestStatus = this.periodList[0].payRoleLatestStatus;
              this.selectedPeriodId = periodId;
            } 
            else 
            {
              this.payRoleLatestStatus = this.periodList[0].payRoleLatestStatus;
            }
          }
        }
        if (this.showGeneralDetails === true) {
          this.getAllGeneralTabData(this.selectedStoreId, this.selectedPeriodId);
        }
        else if (this.showDistrinctDetails === true) {
          this.getAllDistrictTabData(this.selectedStoreId, this.selectedPeriodId);
        }
        else if (this.showPayrollDetails === true) {
          this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId)
        }
      }
      else
      {
        this.saveMessage = "No data to load for this store";
        this.messageType = "error";

        this.getAllGeneralTabData(this.selectedStoreId, 0);//no periods so nothing to really load

      }
    });


    // }else{
    //     this.spinner.hide();
    // }

    // });
  }
  private getPollFilePayPeriodAlone(storeId: string, payPeriodId: number) {
    //this.spinner.show();
    this._data.getPollFilePayPeriods(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      this.pollfileLookUp = obj;
      this.periodList = obj;
      if (this.periodList.length > 0) {
        // var periodId = this.periodList[0].periodId;
        // if (this.selectedPeriodId === undefined || this.selectedPeriodId == null) {
        //   this.selectedPeriodId = periodId;
        // }
        var CurrentPeriod = this.periodList.filter(x => x.selectedPeriod == 1);
        if (CurrentPeriod && CurrentPeriod.length > 0) {
          this.selectedPeriodId = CurrentPeriod[0].periodId;
          this.payRoleLatestStatus = this.periodList[0].payRoleLatestStatus;
        } else {
          var periodId = this.periodList[0].periodId;
          if (this.selectedPeriodId === undefined || this.selectedPeriodId == null) {
            this.payRoleLatestStatus = this.periodList[0].payRoleLatestStatus;
            this.selectedPeriodId = periodId;
          } else {
            this.payRoleLatestStatus = this.periodList[0].payRoleLatestStatus;
          }
        }
      } else {
        //this.spinner.hide();
      }

    });
  }
  private getAllGeneralTabData(storeId: string, payPeriodId: number) {
    // this.getGeneralOpenPollFileData(storeId, payPeriodId);
    // this.getGeneralSubmittingPollFileData(this.selectedStoreId, this.selectedPeriodId);
    this.getGenerallFileData(storeId, payPeriodId);
  }
  private getGenerallFileData(storeId: string, payPeriodId: number) {
    this.generalOpenfileData = [];
    this.generalOpenDataSource = null;
    this.generalOpenfileData = [];
    this.generalSubmittingDataSource = null;
    this.generalbaseModel = null;
    this.spinner.show();
    this._data.getPollFileList(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      this.generalbaseModel = obj;

      //this.generalOpenfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Open && x.exceptionType.trim().toLowerCase() != 'clean' && !x.isMPVWaiverFound);
      this.generalOpenfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Open);
      
      this.generalOpenDataSource = new MatTableDataSource(this.generalOpenfileData);
      this.generalOpenDataSource.paginator = this.paginator5;
      this.generalOpenDataSource.sort = this.sort5;

      this.generalSubmittingfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Submitting);
      this.generalSubmittingDataSource = new MatTableDataSource(this.generalSubmittingfileData);
      this.generalSubmittingDataSource.paginator = this.paginator6;
      this.generalSubmittingDataSource.sort = this.sort6;
      this.spinner.hide();

      if(obj.reviewData.length==0){
        this.saveMessage = "No data to load for this store";
        this.messageType = "error";
      }
      else
      {
        this.saveMessage = "";
        this.messageType = ""; 
      }
    });
  }

  private getGeneralOpenPollFileData(storeId: string, payPeriodId: number) {
    this.generalOpenfileData = [];
    this.generalOpenDataSource = null;
    this.spinner.show();
    this._data.getGeneralPollFileList(storeId, payPeriodId, PayrollStatus.Open).subscribe(result => {
      const obj = result.json();
      this.generalOpenbaseModel = obj;
      this.spinner.hide();
      this.generalOpenfileData = this.generalOpenbaseModel.reviewData;
      this.generalOpenDataSource = new MatTableDataSource(this.generalOpenfileData);
      this.generalOpenDataSource.paginator = this.paginator5;
      this.generalOpenDataSource.sort = this.sort5;
    });
  }

  private getGeneralSubmittingPollFileData(storeId: string, payPeriodId: number) {
    this.spinner.show();
    this._data.getGeneralPollFileList(storeId, payPeriodId, PayrollStatus.Approved).subscribe(result => {
      const obj = result.json();
      this.generalSubmittingbaseModel = obj;
      this.generalSubmittingfileData = this.generalSubmittingbaseModel.reviewData;
      this.generalSubmittingDataSource = new MatTableDataSource(this.generalSubmittingfileData);
      this.generalSubmittingDataSource.paginator = this.paginator6;
      this.generalSubmittingDataSource.sort = this.sort6;
      this.spinner.hide();
    });
  }
  private getAllDistrictTabData(storeId: string, payPeriodId: number) {
    // this.getDistrinctOpenPollFileData(storeId, payPeriodId);
    // this.getDistrinctWaitingApprovalPollFileData(this.selectedStoreId, this.selectedPeriodId)
    this.getDistrictlFileData(storeId, payPeriodId);
    this.getDistrinctSummaryPollFileData(this.selectedStoreId, this.selectedPeriodId);
  }
  private getDistrictlFileData(storeId: string, payPeriodId: number) {
    this.distrinctOpenfileData = [];
    this.distrinctOpenDataSource = null;
    this.distrinctWaitingApprovedfileData = [];
    this.distrinctWaitingApprovedDataSource = null;
    this.distrinctApprovedfileData = [];
    this.distrinctApprovedDataSource = null;
    this.generalbaseModel = null;
    this.spinner.show();
    this._data.getPollFileList(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      this.generalbaseModel = obj;
      this.distrinctOpenfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Open);
      this.distrinctOpenDataSource = new MatTableDataSource(this.distrinctOpenfileData);
      this.distrinctOpenDataSource.paginator = this.paginator7;
      this.distrinctOpenDataSource.sort = this.sort7;

      this.distrinctWaitingApprovedfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Submitting);
      this.distrinctWaitingApprovedDataSource = new MatTableDataSource(this.distrinctWaitingApprovedfileData);
      this.distrinctWaitingApprovedDataSource.paginator = this.paginator8;
      this.distrinctWaitingApprovedDataSource.sort = this.sort8;
      this.distrinctWaitingSelection.clear();

      this.distrinctApprovedfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.WaitingApproval);
      this.distrinctApprovedDataSource = new MatTableDataSource(this.distrinctApprovedfileData);
      this.distrinctApprovedDataSource.paginator = this.paginator9;
      this.distrinctApprovedDataSource.sort = this.sort9;
      this.spinner.hide();

      if(obj.reviewData.length==0){
        this.saveMessage = "No data to load for this store";
        this.messageType = "error";
      }
      else
      {
        this.saveMessage = "";
        this.messageType = ""; 
      }

    });
  }

  private getDistrinctOpenPollFileData(storeId: string, payPeriodId: number) {
    this.distrinctOpenfileData = [];
    this.distrinctOpenDataSource = null;
    this.spinner.show();
    this._data.getDistinctPollFileList(storeId, payPeriodId, PayrollStatus.Open).subscribe(result => {
      const obj = result.json();
      this.distrinctOpenbaseModel = obj;
      this.spinner.hide();
      this.distrinctOpenfileData = this.distrinctOpenbaseModel.reviewData;
      this.distrinctOpenDataSource = new MatTableDataSource(this.distrinctOpenfileData);
      this.distrinctOpenDataSource.paginator = this.paginator7;
      this.distrinctOpenDataSource.sort = this.sort7;
    });
  }

  private getDistrinctWaitingApprovalPollFileData(storeId: string, payPeriodId: number) {
    this.spinner.show();
    this._data.getDistinctPollFileList(storeId, payPeriodId, PayrollStatus.Approved).subscribe(result => {
      const obj = result.json();
      this.spinner.hide();
      this.distrinctWaitingApprovedbaseModel = obj;
      this.distrinctWaitingApprovedfileData = this.distrinctWaitingApprovedbaseModel.reviewData;
      this.distrinctWaitingApprovedDataSource = new MatTableDataSource(this.distrinctWaitingApprovedfileData);
      this.distrinctWaitingApprovedDataSource.paginator = this.paginator8;
      this.distrinctWaitingApprovedDataSource.sort = this.sort8;
      this.distrinctWaitingSelection.clear();
    });
  }

  private getDistrinctSummaryPollFileData(storeId: string, payPeriodId: number) {
    this.spinner.show();
    this._data.getPollFileSummaryList(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      this.distrinctSummarybaseModel = obj;
      this.distrinctSummaryfileData = this.distrinctSummarybaseModel.reviewData;
      this.distrinctSummaryDataSource = new MatTableDataSource(this.distrinctSummaryfileData);
      this.distrinctSummaryDataSource.paginator = this.paginator10;
      this.distrinctSummaryDataSource.sort = this.sort10;
      this.spinner.hide();
    });
  }
  private getAllPayrollTabsData(storeId: string, payPeriodId: number) {
    // this.getPayrollOpenPollFileData(storeId, payPeriodId);
    // this.getApprovedPollFileData(this.selectedStoreId, this.selectedPeriodId);
    this.getPayrollFileData(storeId, payPeriodId);
    this.getPayrollSummaryPollFileData(this.selectedStoreId, this.selectedPeriodId);
  }
  private getPayrollFileData(storeId: string, payPeriodId: number) {
    this.pollfileData = [];
    this.dataSource = null;
    this.PayrollWaitingapprovedPollfileData = [];
    this.PayrollWaitingapprovedDataSource = null;
    this.PayrollapprovedPollfileData = [];
    this.PayrollapprovedDataSource = null;
    this.generalbaseModel = null;
    this.spinner.show();
    this._data.getPollFileList(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      //this.generalbaseModel = new PollFileBaseModel;
      this.generalbaseModel = obj;
      //this.pollfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Open && x.exceptionType.trim().toLowerCase() != 'clean' && !x.uploadedFileName);
      this.pollfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Open);
      this.dataSource = new MatTableDataSource(this.pollfileData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.PayrollWaitingapprovedPollfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.Submitting);
      this.PayrollWaitingapprovedDataSource = new MatTableDataSource(this.PayrollWaitingapprovedPollfileData);
      this.PayrollWaitingapprovedDataSource.paginator = this.paginator2;
      this.PayrollWaitingapprovedDataSource.sort = this.sort2;
      this.payrollWaitingSelection.clear();
      this.isSelectAllDistrictWaiting=false;
      // this.masterTogglePayrollWaiting();

      this.PayrollapprovedPollfileData = this.generalbaseModel.reviewData.filter(x => x.payRoleStatusID == PayrollStatus.WaitingApproval);
      this.PayrollapprovedDataSource = new MatTableDataSource(this.PayrollapprovedPollfileData);
      this.PayrollapprovedDataSource.paginator = this.paginator12;
      this.PayrollapprovedDataSource.sort = this.sort12;

      this.spinner.hide();

      if(obj.reviewData.length==0){
        this.saveMessage = "No data to load for this store";
        this.messageType = "error";
      }
      else
      {
        this.saveMessage = "";
        this.messageType = ""; 
      }
    });
  }

  private getPayrollOpenPollFileData(storeId: string, payPeriodId: number) {
    this.pollfileData = [];
    this.dataSource = null;
    this._data.getPayrollPollFileList(storeId, payPeriodId, PayrollStatus.Open).subscribe(result => {
      const obj = result.json();
      this.pollbaseModel = obj;
      this.pollfileData = this.pollbaseModel.reviewData;
      this.dataSource = new MatTableDataSource(this.pollfileData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  private getApprovedPollFileData(storeId: string, payPeriodId: number) {
    this.spinner.show();
    this._data.getPayrollPollFileList(storeId, payPeriodId, PayrollStatus.Approved).subscribe(result => {
      const obj = result.json();
      this.spinner.hide();
      this.PayrollWaitingapprovedPollbaseModel = obj;
      this.PayrollWaitingapprovedPollfileData = this.PayrollWaitingapprovedPollbaseModel.reviewData;
      this.PayrollWaitingapprovedDataSource = new MatTableDataSource(this.PayrollWaitingapprovedPollfileData);
      // this.PayrollWaitingapprovedDataSource.paginator = this.paginator2;
      // this.PayrollWaitingapprovedDataSource.sort = this.sort2;
      this.masterTogglePayrollWaiting();
      this.showPayPeriodControl();
    });
  }

  // private getRejectedPollFileData(storeId: string, payPeriodId: number) {
  //   this.spinner.show();
  //   this._data.getPayrollPollFileList(storeId, payPeriodId, PayrollStatus.Rejected).subscribe(result => {
  //     const obj = result.json();
  //     this.rejectedPollbaseModel = obj;
  //     this.rejectedPollfileData = this.rejectedPollbaseModel.reviewData;
  //     // Assign the data to the data source for the table to render
  //     this.rejectedDataSource = new MatTableDataSource(this.rejectedPollfileData);
  //     this.rejectedDataSource.paginator = this.paginator3;
  //     this.rejectedDataSource.sort = this.sort3;
  //     this.spinner.hide();
  //   });
  // }

  // private getRemovedPollFileData(storeId: string, payPeriodId: number) {
  //   this.spinner.show();
  //   this._data.getPayrollPollFileList(storeId, payPeriodId, PayrollStatus.Removed).subscribe(result => {
  //     const obj = result.json();
  //     this.removedPollbaseModel = obj;
  //     this.removedPollfileData = this.removedPollbaseModel.reviewData;
  //     this.removedDataSource = new MatTableDataSource(this.removedPollfileData);
  //     this.removedDataSource.paginator = this.paginator4;
  //     this.removedDataSource.sort = this.sort4;
  //     this.spinner.hide();
  //   });
  // }

  private getPayrollSummaryPollFileData(storeId: string, payPeriodId: number) {
    this.spinner.show();
    this._data.getPollFileSummaryList(storeId, payPeriodId).subscribe(result => {
      const obj = result.json();
      this.payrollSummaryPollbaseModel = obj;
      this.payrollSummaryPollfileData = this.payrollSummaryPollbaseModel.reviewData;
      this.payrollSummaryDataSource = new MatTableDataSource(this.payrollSummaryPollfileData);
      this.payrollSummaryDataSource.paginator = this.paginator11;
      this.payrollSummaryDataSource.sort = this.sort11;
      this.spinner.hide();
    });
  }


  private getPollFileLookup() {
    this._data.getPollFileLookups().subscribe(result => {
      const obj = result.json();
      this.pollfileLookUp = obj;
      // this.storeList = this.pollfileLookUp.stores;
      this.periodList = this.pollfileLookUp.periods;
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches

    if (this.showGeneralDetails) {
      this.generalOpenDataSource.filter = filterValue;
      this.generalSubmittingDataSource.filter = filterValue;
    } else if (this.showDistrinctDetails) {
      this.distrinctOpenDataSource.filter = filterValue;
      this.distrinctWaitingApprovedDataSource.filter = filterValue;
      this.distrinctWaitingSelection.clear();
      this.distrinctApprovedDataSource.filter = filterValue;
      this.distrinctSummaryDataSource.filter = filterValue;
    } else if (this.showApprovedTab) {
      this.dataSource.filter = filterValue;
      this.PayrollWaitingapprovedDataSource.filter = filterValue;
      this.payrollWaitingSelection.clear();
      this.PayrollapprovedDataSource.filter = filterValue;
      // this.rejectedDataSource.filter = filterValue;
      // this.removedDataSource.filter = filterValue;
      this.payrollSummaryDataSource.filter = filterValue;
      // this.newDataSource.filter = filterValue;
    }
  }

  // isAllSelected() {
  //   const numSelected = this.distrinctSelection.selected.length;
  //   const numRows = this.distrinctOpenDataSource.data.length;
  //   return numSelected === numRows;
  // }

  // isAllSelected1() {
  //   const numSelected = this.distrinctSelection1.selected.length;
  //   const numRows = this.distrinctOpenDataSource.data.length;
  //   return numSelected === numRows;
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterDistrinctToggle() {
  //     this.isAllSelected() ?
  //     this.distrinctSelection.clear() :
  //     this.distrinctOpenDataSource.data.forEach(row => this.distrinctSelection.select(row));
  // }

  // masterDistrinctToggle1() {
  //     this.isAllSelected1() ?
  //     this.distrinctSelection1.clear() :
  //     this.distrinctOpenDataSource.data.forEach(row => this.distrinctSelection1.select(row));
  // }

  chkIsSelectAllApproved(value: any) {
    for (let obj of this.distrinctOpenDataSource.data) {
      obj.checkedApproved = this.isSelectAllApproved;
      obj.checkedRejected = false;
    }
  }
  clkADPExport() {

    let reportURL: string;

    // if (this.selectedPeriodId && this.selectedStoreId) {
    //   if (this.ReportAutoLogin) {
    //     reportURL = this.serverBaseURL + "api/Poll/GetADPFile?ReportName=ADPExport&ReportFormat=excel&ReportDownloadName=ADPExport.xlsx&Parameters=Format=EXCELOPENXML~pPeriodID=" + this.selectedPeriodId + "~pStoreID=" + this.selectedStoreId + "";
    //   } else { reportURL = this.reportURLExpression.replace("{{ReportName}}", "ADPExport") + "Format=EXCELOPENXML&pPeriodID=" + this.selectedPeriodId + "&pStoreID=" + this.selectedStoreId; }
      
      reportURL = this.serverBaseURL + "api/Poll/GetADPFile?PeriodId=" + this.selectedPeriodId + "&StoreId=" + this.selectedStoreId;
      
      window.open(reportURL, '_blank');





    // this._data.RenderADPFile(this.selectedStoreId, this.selectedPeriodId).subscribe(response => {
    //   if (response) {
    //     this.saveMessage = "ADP Generated";
    //     this.messageType = "success";
        
    //     let csvData = response; 
    //     console.log(csvData) 
    //     let blob = new Blob(['\ufeff' + csvData], { 
    //       type: 'text/csv;charset=utf-8;'
    //     }); 
    //     let dwldLink = document.createElement("a"); 
    //     let url = URL.createObjectURL(blob); 
    
    //     dwldLink.setAttribute("href", url); 
    //     dwldLink.setAttribute("download", "adp" + ".csv"); 
    //     dwldLink.style.visibility = "hidden"; 
    //     document.body.appendChild(dwldLink); 
    //     dwldLink.click(); 
    //     document.body.removeChild(dwldLink); 


    //     this.spinner.hide();
    //   }
    //   error => {
    //     this.saveMessage = "File Not received.";
    //     this.messageType = "error";
    //     this.spinner.hide();
    //   }
    // });
  }


  





  savePayrollSummaryDetails() {
    let data2: PollFileModel[] = this.payrollSummaryDataSource.data.filter(x => x.sickTime > 0 && x.sickTime > x.sickBalance);

    this.exceedSickBalanceMessage = "";
    if(data2.length>0)
    {
      for (var i=0;i<data2.length;i++) {
        this.exceedSickBalanceMessage = this.exceedSickBalanceMessage + " " + data2[i].fileNumber;
      }

      alert("Save cancelled. The following employees have Sick Time entered that have exceeded their remaining sick balance. Please correct and try to Save again. \n\nFile Number(s) : " + this.exceedSickBalanceMessage)
      
      return;
    }

    let data: PollFileModel[] = this.payrollSummaryDataSource.data.filter(x => x.sickTime > 0);
    this.spinner.show();
    this._data.savePayrollSummaryDetails(data, this.selectedStoreId, this.selectedPeriodId).subscribe(response => {
      if (response) {
        this.saveMessage = "Sick and Vacation time saved.";
        this.messageType = "success";
        this.spinner.hide();
      }
      error => {
        this.saveMessage = "Not saved.";
        this.messageType = "error";
        this.spinner.hide();
      }
    });
  }
  ApprovedDistrict() {
    if (this.distrinctWaitingApprovedDataSource.data.length > 0) {
      let selectedValues:PollFileModel[];
      if(this.distrinctWaitingSelection&&this.distrinctWaitingSelection.selected.length>0)
      {
        selectedValues=this.distrinctWaitingSelection.selected;
      }
      else
      {
        this.saveMessage = "No records selected.";
        this.messageType = "error";
      }
      this.spinner.show();
      //this.spinner.show();
      this._data.ApproveDistrictPayrollWaitingforApproval(selectedValues, this.selectedStoreId, this.selectedPeriodId).subscribe(response => {

        this.saveMessage = "Approved.";
        this.messageType = "success";

        this.filterAdminChanged();

        this.spinner.hide();

        if (response) {         



          //this.getAllDistrictTabData(this.selectedStoreId, this.selectedPeriodId);
          //this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);

         

          // this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
          this.saveMessage = "Approved.";
          this.messageType = "success";
        }
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        }
      });
    }
    else {
      this.saveMessage = "No Records to Approve.";
      this.messageType = "error";
    }
  }
  ApprovedPayroll() {
    if (this.PayrollWaitingapprovedDataSource.data.length > 0) {
    
      let selectedValues:PollFileModel[];
      if(this.payrollWaitingSelection&&this.payrollWaitingSelection.selected.length>0)
      {
        selectedValues=this.payrollWaitingSelection.selected;
      }
      else
      {
        this.saveMessage = "No records selected.";
        this.messageType = "error";
      }
      this.spinner.show();
      this._data.ApproveDistrictPayrollWaitingforApproval(selectedValues, this.selectedStoreId, this.selectedPeriodId).subscribe(response => {
        if (response) {
          this.getAllPayrollTabsData(this.selectedStoreId, this.selectedPeriodId);
          this.getPollFilePayPeriodAlone(this.selectedStoreId, this.selectedPeriodId);
          this.saveMessage = "Approved.";
          this.messageType = "success";
        }
        error => {
          this.saveMessage = "Not saved.";
          this.messageType = "error";
          this.spinner.hide();
        }
      });
    }
    else {
      this.saveMessage = "No Records to Approve.";
      this.messageType = "error";
    }
  }
  clkApprovedDistrict() {

    if(this.distrinctWaitingSelection&&this.distrinctWaitingSelection.selected.length==0)
    {
      this.dialog.open(ConfirmDialog, {
        width: '600px',
        data: { title: 'No records selected. Please select 1 record to approve ' }
      });
    }
    else
    {
      let confirmDialogRef = this.dialog.open(ConfirmDialog, {
        width: '600px',
        data: { title: 'Once approved, records can not be changed. Are you sure you would like to approve the selected record? ' }
      });

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ApprovedDistrict();
        }
      });
    }
  }
  clkApprovedPayroll() {
    if(this.payrollWaitingSelection&&this.payrollWaitingSelection.selected.length==0)
    {
      this.dialog.open(ConfirmDialog, {
        width: '600px',
        data: { title: 'No records selected. Please select 1 record to approve ' }
      });
    }
    else
    {
      let confirmDialogRef = this.dialog.open(ConfirmDialog, {
        width: '600px',
        data: { title: 'Once approved, records can not be changed. Are you sure you would like to approve the selected record?' }
      });

      confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ApprovedPayroll();
        }
      });
    }
  }
  isAllSelectedDistrictWaiting() {
    const numSelected = this.distrinctWaitingSelection.selected.length;
    const numRows = this.distrinctWaitingApprovedDataSource?this.distrinctWaitingApprovedDataSource.data.length:0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleDistrictWaiting() {
    this.isAllSelectedDistrictWaiting() ?
      this.distrinctWaitingSelection.clear() :
      this.distrinctWaitingApprovedDataSource.data.forEach(row => this.distrinctWaitingSelection.select(row));
    this.distrinctWaitingApprovedDataSource.paginator = this.paginator8;
    this.distrinctWaitingApprovedDataSource.sort = this.sort8;
  }

  isAllSelectedPayrollWaiting() {
    const numSelected = this.payrollWaitingSelection.selected.length;
    const numRows = this.PayrollWaitingapprovedDataSource?this.PayrollWaitingapprovedDataSource.data.length:0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterTogglePayrollWaiting() {
    this.isAllSelectedPayrollWaiting() ?
      this.payrollWaitingSelection.clear() :
      this.PayrollWaitingapprovedDataSource.data.forEach(row => this.payrollWaitingSelection.select(row));
      this.PayrollWaitingapprovedDataSource.paginator = this.paginator2;
      this.PayrollWaitingapprovedDataSource.sort = this.sort2;

  }
}



