import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, UserService } from '../service/index'
import { User, Store, UserRole } from '../datamodal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialog } from '../dialogs/confirm.dialog';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    moduleId: module.id,
    selector: "register-component",
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent {

    @Input() UserRole: string;
    @Output() clkClosePopup = new EventEmitter<any>();
    model: any = {};
    loading = false;
    selectedOptions: any;
    saveMessage: string;
    messageType: string;
    messageSuccess: string;
    current_selected: string;
    storeList: Store[] = [];
    filteredItems: Store[] = [];
    selectedStoreList: Store[] = [];
    selectAllPressed: boolean = false;
    user: User;
    userList: User[] = [];
    passwordMatch: boolean;
    message: string;
    showSubmitForm: boolean = false;
    showRegisterForm: boolean = true;
    form: FormGroup;
    fullNameFormControl = new FormControl('', [Validators.required]);
    userNameFormControl = new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]);
    addressFormControl = new FormControl('', [Validators.required]);
    phoneNumberFormControl = new FormControl('', [Validators.required]);
    passwordFormControl = new FormControl('', [Validators.required]);
    confirmPasswordFormControl = new FormControl('', [Validators.required]);
    roleFormControl = new FormControl('', [Validators.required]);
    roleList: UserRole[] = [];
    selectedRole: string;
    hidePassword: boolean = false;

    private validateRequired(): boolean {
        if (this.UserRole == "Admin" && this.hidePassword == false) {
            if (!this.fullNameFormControl.invalid.valueOf()
                && !this.userNameFormControl.invalid.valueOf()
                && !this.passwordFormControl.invalid.valueOf()
                && !this.confirmPasswordFormControl.invalid.valueOf()
                && !this.roleFormControl.invalid.valueOf()
            ) {
                return true;
            }
            else { return false; }
        } 
        else if (this.UserRole == "Admin" && this.hidePassword == true) {
            if (!this.fullNameFormControl.invalid.valueOf()
                && !this.userNameFormControl.invalid.valueOf()
                && !this.roleFormControl.invalid.valueOf()
            ) {
                return true;
            }
            else { return false; }
        }
        else if (this.hidePassword == true) {
            if (!this.fullNameFormControl.invalid.valueOf()
                && !this.userNameFormControl.invalid.valueOf()
            ) {
                return true;
            }
            else { return false; }
        }
        else {
            if (!this.fullNameFormControl.invalid.valueOf()
                && !this.userNameFormControl.invalid.valueOf()
                && !this.passwordFormControl.invalid.valueOf()
                && !this.confirmPasswordFormControl.invalid.valueOf()
            ) {
                return true;
            }
            else { return false; }
        }        

    }

    private validateUnSaved(): boolean {
        if (this.UserRole == "Admin" && this.hidePassword == false) {
            if (!this.fullNameFormControl.dirty
                && !this.userNameFormControl.dirty
                && !this.passwordFormControl.dirty
                && !this.confirmPasswordFormControl.dirty
                && !this.roleFormControl.dirty
            ) {
                return true;
            }
            else {
                return false;
            }
        } 
        else if (this.UserRole == "Admin" && this.hidePassword == true) {
            if (!this.fullNameFormControl.dirty
                && !this.userNameFormControl.dirty
                && !this.roleFormControl.dirty
            ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.hidePassword == true) {
            if (!this.fullNameFormControl.dirty
                && !this.userNameFormControl.dirty
                && !this.roleFormControl.dirty
            ) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (!this.fullNameFormControl.dirty
                && !this.userNameFormControl.dirty
                && !this.passwordFormControl.dirty
                && !this.confirmPasswordFormControl.dirty
                && !this.roleFormControl.dirty
            ) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    constructor(
        private router: Router,
        private userService: UserService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService,
        public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {

            if(data!=null && data.user!=null)
            {
                this.model.username = data.user.userName;

                this.loadUser();
            }
    }
    ngOnInit() {
        this.loadStores();
        this.getRole();


    }
    redirectToLogin() {
        if (this.UserRole == "Admin") {
            // window.location.reload();
            this.clkClosePopup.emit();

        } else {
            this.router.navigate(['/login']);
        }
    }

    loadStores() {
        this.userService.getStore().subscribe(
            data => {
                this.filteredItems = data;
                this.storeList = data;
            },
            error => {
                this.alertService.error(error);
            });
    }

    selectAll()
    {
        if(this.selectAllPressed == true)
        {
            this.selectAllPressed  = false;
        }
        else
        {
            this.selectAllPressed  = true;
        }
        
        this.filteredItems.forEach(element => {
         
                 element.selected = this.selectAllPressed;
                this.selectedStoreList.push({storeId:element.storeId,id:null,selected:true,addressLine1:null,addressLine2:null});
           
        });
    }

    onSelection(params, item) {
        this.current_selected = params.option.value;
        var isChecked = params.option.selected;
        if (isChecked == true) {
            var result = this.selectedStoreList.filter(x => x.storeId == params.option.value.storeId)
            if (result.length > 0) {

            } else {
                this.selectedStoreList.push(params.option.value);
            }

        }
        else if (isChecked == false) {
            this.selectedStoreList = this.selectedStoreList.filter(x => x.storeId != params.option.value.storeId);
        }
    }

    assignCopy() {
        this.filteredItems = Object.assign([], this.storeList);
    }

    filterItem(itemDetail: any) {
        this.filteredItems = Object.assign([], this.storeList).filter(
            item => item.storeId.toString().toLowerCase().indexOf(itemDetail.toString().toLowerCase()) > -1
                || item.storeId.toString().toLowerCase().indexOf(itemDetail.toString().toLowerCase()) > -1
        )
        this.filteredItems.forEach(element => {
            var temp = this.selectedStoreList.find(x => x.id == element.id);
            if (temp) {
                element.selected = true;
            }
        });
        if (this.filteredItems.length == 0) {
            this.saveMessage = "Stores not found!!!";
            this.messageType = "info";
        }
        else {
            this.saveMessage = "";
            this.messageType = "info";
        }

    }

    passwordsMatch(password: string, confirmedPassword: string) {
        if (password !== confirmedPassword) {
            return false;
        } else {
            return true;
        }
    }

    register() {
        this.spinner.show();
        if (this.validateRequired()) {

            this.passwordMatch = this.passwordsMatch(this.model.password, this.model.confirmPassword)

            if (this.passwordMatch === false && !this.hidePassword) {
                this.message = "Password doesn't match.";
                this.spinner.hide();
            }
            else if (!this.selectedStoreList || this.selectedStoreList.length == 0) {
                this.message = "Select at least 1 store.";
                this.spinner.hide();
            }
            else {
                this.loading = true;
                let totalStoreId: "";
                let selectedVal = this.roleList.filter(x => x.roleId == this.model.roleId);
                if (selectedVal && selectedVal.length > 0) {
                    this.selectedRole = selectedVal[0].roleName;
                }
                this.selectedStoreList.forEach(element => {
                    this.user = new User;
                    this.user.fullName = this.model.fullName,
                        this.user.username = this.model.username
                    //this.user.address = this.model.address
                    //this.user.phoneNumber = this.model.phoneNumber
                    this.user.password = this.model.password
                    this.user.storeId = element.storeId
                    if (this.UserRole == 'Admin') {
                        this.user.roleId = this.model.roleId;
                    }

                    if (totalStoreId === undefined) {
                        totalStoreId = "";
                        totalStoreId += element.storeId
                    }
                    else {
                        totalStoreId += "," + element.storeId
                    }
                    this.userList.push(this.user);
                });

                this.userService.saveRequestSiteAccess(this.userList)
                    .subscribe(
                        data => {
                            this.messageSuccess = "Thank you for submitting your request for Store Id(s)" + totalStoreId + " You will be notified through email once your request is approved.";
                            this.showRegisterForm = false;
                            this.showSubmitForm = true;
                            this.loading = false;
                            this.spinner.hide();
                        },
                        error => {
                            this.alertService.error(error);
                            this.loading = false;
                            this.spinner.hide();
                        });
            }
        }
        else {
            this.message = "Please provide all the required info.";
            this.spinner.hide();
        }
    }
    getRole() {
        this.userService.getRole().subscribe(result => {
            const obj = result;
            this.roleList = obj;
        })
    }
    closeDialog(event:any)
    {
        this.model.username=null;
        this.clkClosePopup.emit();
    }
    focusoutemailinput() {
        if (this.model.username) {
            this.spinner.show();
            this.userService.GetUserDetailsByUsername(this.model.username).subscribe(result => {
                const obj = result;
                var userStoreList: User[] = obj;
                this.spinner.hide();
                if (userStoreList && userStoreList.length > 0) {
                    let confirmDialogRef = this.dialog.open(ConfirmDialog, {
                        width: '400px',
                        data: { title: 'The User already have access. Are you sure you want to re-create the access?' }
                    });
                    confirmDialogRef.afterClosed().subscribe(result => {
                        if (result != undefined) {
                            this.hidePassword = true;
                            this.model.fullName = userStoreList[0].fullName;
                            this.model.roleId = userStoreList[0].roleId;
                            this.model.phoneNumber = userStoreList[0].phoneNumber;
                            this.model.address = userStoreList[0].address;
                            this.selectedStoreList=[];
                            this.filteredItems.forEach(element => {
                                var temp = userStoreList.find(x => x.storeId == element.storeId);
                                if (temp) {
                                    element.selected = true;
                                    this.selectedStoreList.push({storeId:temp.storeId,id:null,selected:true,addressLine1:null,addressLine2:null});
                                }
                            });
                        }else{
                            this.model.username=null; 
                            this.message ="User name already exists";
                        }
                    });

                }else
                {
                    this.message ="";
                }
            });
        }
    }

    loadUser() {
        this.hidePassword = true;
        if (this.model.username) {
            this.spinner.show();
            this.userService.GetUserDetailsByUsername(this.model.username).subscribe(result => {
                const obj = result;
                var userStoreList: User[] = obj;
                this.spinner.hide();
                if (userStoreList && userStoreList.length > 0) {
                //     let confirmDialogRef = this.dialog.open(ConfirmDialog, {
                //         width: '400px',
                //         data: { title: 'The User already have access. Are you sure you want to re-create the access?' }
                //     });
                    // confirmDialogRef.afterClosed().subscribe(result => {
                    //     if (result != undefined) {
                            this.model.fullName = userStoreList[0].fullName;
                            this.model.roleId = userStoreList[0].roleId;
                            //this.model.phoneNumber = userStoreList[0].phoneNumber;
                           // this.model.address = userStoreList[0].address;
                            this.selectedStoreList=[];
                            this.filteredItems.forEach(element => {
                                var temp = userStoreList.find(x => x.storeId == element.storeId);
                                if (temp) {
                                    element.selected = true;
                                    this.selectedStoreList.push({storeId:temp.storeId,id:null,selected:true,addressLine1:null,addressLine2:null});
                                }
                            });
                        // }else{
                        //     this.model.username=null; 
                        //     this.message ="User name already exists";
                    //     }
                    // }
                // );

             }else
             {
                 //user came here from edit page so the fact that there are no stores, force user to enter a valid username
                 //users should always have at least 1 store
                this.model.username = "";
                    this.message ="";
                }
            });
        }
    }

}



