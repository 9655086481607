export class Work_clocksetsModal{
clocksets_uid :string;
employee_uid :string;
clockin :string;
clockout :string;
payrate :string;
employee_types_uid:string;
deleted:string;
importHeaderId:string;
IsBadData:Boolean;
ShiftDate:Date;
PunchInShiftDate:Date;
PunchOutShiftDate:Date;
shiftMinutes:string;
ShiftDateCutoff:Date;
IsSplitShift:Boolean;
IsMealViolation:Boolean;

isselectedImportHeaderId:Boolean;
}

